import './Location.scss';

import React, { Component, Fragment } from 'react';
import { forEach, get, keys, pickBy, set } from 'lodash';

import AddTaskModal from './AddTaskModal';
import { AuthConsumer } from '../../context/AuthContext';
import CheckInput from '../Form/CheckInput';
import Contracts from './Contracts';
import { DateTime } from 'luxon';
import EditableInput from '../Form/EditableInput';
import { FormConsumer } from '../../context/FormContext';
import InPageNavigation from '../InPageNavigation/InPageNavigation';
import LocationJobs from './LocationJobs';
import MapInput from '../Form/MapInput';
import { NavLink } from 'react-router-dom';
import Popconfirm from 'antd/es/popconfirm';
import { Redirect } from 'react-router-dom';
import { Request } from '../../api/Request';
import SelectInput from '../Form/SelectInput';
import { Switch } from 'antd';
import { ReactComponent as TransferIcon } from '../../assets/icons/paper.svg';
import TransferModal from './TransferModal';
import TransferModalLoading from './TransferModalLoading';
import { ReactComponent as TrashIcon } from '../../assets/icons/trash.svg';
import { ReactComponent as _CopyIcon } from '../../assets/icons/copy.svg';
import getTasks from './getTasks';
import { notification } from 'antd';
import prepareForDropdown from '../helper/prepareForDropdown';
// import renderAdditionalTasks from './renderAdditionalTasks';
import renderTask from './renderTask';
import styled from 'styled-components';
import Files from './Files';

const _Copy = styled.span`
  svg {
    width: 16px;
    height: 16px;
    margin: 0 12px;
    cursor: pointer;
    display: inline-block;
    fill: #009842;
    color: #009842;
  }
`;

const CopyIcon = styled(_CopyIcon)`
  fill: #009842;
  color: #009842;
  width: 16px;
  height: 16px;
`;

const CopyButton = (props) => {
  return (
    <_Copy
      onClick={() => {
        navigator.clipboard.writeText(props.data);
        notification.open({
          message: 'Zwischenablage',
          description: 'Die Adresse wurde in die Zwischenablage kopiert.',
          icon: <CopyIcon style={{ color: '#009842' }} />,
        });
      }}
    >
      <CopyIcon />
    </_Copy>
  );
};

const vds_due_text =
  'Die Fälligkeit kann nachträglich nicht mehr in die Vergangenheit geändert werden. Sind Sie sich sicher?';

class LocationEdit extends Component {
  state = {
    filterActive: true,
    redirectList: false,
    error: null,
    isLoaded: false,
    projectRunning: false,
    item: {
      price: {},
      customer: {},
    },
    customers: [],
    customerNumbers: [],
    customerGroups: [],
    due: [],
    tasks: [],
    editing: false,
    modalIsOpen: false,
    projectModalIsOpen: false,
  };

  constructor(props) {
    super(props);
    this.save = this.save.bind(this);
    this.remove = this.remove.bind(this);
    this.removeAdditionalTask = this.removeAdditionalTask.bind(this);
    this.handleError = this.handleError.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.openProjectModal = this.openProjectModal.bind(this);
    this.closeProjectModal = this.closeProjectModal.bind(this);
    this.project = this.project.bind(this);
    this.addTask = this.addTask.bind(this);
    this.getPriceForTask = this.getPriceForTask.bind(this);
  }

  setPromisifiedState(data) {
    return new Promise((resolve) => this.setState(data, () => resolve()));
  }

  componentDidMount() {
    // console.log('componentDidMount');
    this.loadData().catch(this.handleError);
  }

  handleError(error) {
    // console.log(error);
    this.setState({
      isLoaded: true,
      error,
      redirectList: error.status === 404,
    });
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  openProjectModal() {
    this.setState({ projectModalIsOpen: true });
  }

  closeProjectModal() {
    this.setState({ projectModalIsOpen: false });
  }

  async loadData() {
    const { id } = this.props.match.params;
    const [
      item,
      // due,
      customers,
      tasks,
    ] = await Promise.all([
      Request.get('locations', id, undefined, { displayAllJobs: true }),
      // Request.get("planning/locations", id),
      Request.list('customers', { limit: 25000 }),
      getTasks(),
    ]);
    this.setState({
      isLoaded: true,
      tasks,
      item,
      customers: prepareForDropdown(
        customers.items,
        'Kunde auswählen',
        'name',
        'customernumber'
      ),
      customerNumbers: prepareForDropdown(
        customers.items,
        'Kunde auswählen',
        'customernumber'
      ),
      customerGroups: prepareForDropdown(
        customers.items,
        'Kunde auswählen',
        'group.name'
      ),
    });
  }

  async remove() {
    try {
      await Request.delete('locations', this.state.item._id);
      this.setState({ redirectList: true });
    } catch (error) {
      this.setState({
        isLoaded: true,
        error,
        redirectList: error.status === 404,
      });
    }
  }

  async removeAdditionalTask(taskId) {
    try {
      await Request.delete(
        'locations',
        this.state.item._id,
        `additional-tasks/${taskId}`
      );
      this.loadData();
    } catch (error) {
      this.setState({
        isLoaded: true,
        error,
        redirectList: error.status === 404,
      });
    }
  }

  async saveDue(target, value) {
    const { interval, dues } = this.state.item;
    const done = {};
    done[target] = true;

    let valueAsDateTime =
      typeof value === 'string'
        ? DateTime.fromFormat(value, 'yyyy-MM-dd')
        : DateTime.fromJSDate(value).setLocale('de');
    // eslint-disable-next-line no-restricted-globals
    if (valueAsDateTime.isValid) {
      let force;

      if (valueAsDateTime < DateTime.fromISO(dues[target])) {
        // no due is allowed in the past, so we have to ask the user if he is sure and wants to continue
        if (
          !confirm(
            'Die Fälligkeit liegt vor der aktuellen Fälligkeit. Die Fälligkeit wird im System erzwungen und beachtet kein Intervall mehr. Bei der nächsten Rückpflege wird die Fälligkeit wieder automatisch berechnet. Sind Sie sich sicher?'
          )
        ) {
          return;
        }

        force = true;
      } else {
        // subtract the interval from the current value
        valueAsDateTime = valueAsDateTime.minus({
          months: interval[target],
        });
      }

      Request.put('due', this.state.item._id, {
        done,
        force,
        due: valueAsDateTime.toISO(),
      }).then(
        () => {
          this.loadData();
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
            redirectList: error.status === 404,
          });
        }
      );
    }
  }

  async save(k, value) {
    // if (k === "due.vds") {
    if (k.startsWith('due')) {
      return this.saveDue(k.split('.')[1], value);
    } else {
      const data = set({}, k, value);
      Request.put('locations', this.state.item._id, data).then(
        () => {
          this.loadData();
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
            redirectList: error.status === 404,
          });
        }
      );
    }
  }

  async project(type, selection) {
    try {
      const { item } = this.state;
      const active = keys(pickBy(selection));
      const data = {
        tasks: {},
      };
      forEach(active, (key) => {
        const [t, d] = `${key}.`.split('.');
        if (t === 'task') {
          data.tasks[d] = {
            price: get(item, `price.${d}`),
            defaultTasks: get(item, `defaultTasks.${d}`),
            operatingExpenses: get(item, `operatingExpenses.${d}`),
            interval: get(item, `interval.${d}`),
            checkDepth: get(item, `checkDepth.${d}`),
          };
        } else {
          data[t] = get(item, t);
          if (t === 'priceCombi')
            data.priceCombiCondition = get(item, 'priceCombiCondition'); // also copy priceCombiCondition for priceCombi
        }
      });
      let src = '';
      if (type === 'customergroup') src = 'customer.group._id';
      if (type === 'partner') src = 'customer.partner._id';
      if (type === 'customer') src = 'customer._id';
      const target = get(item, src);

      await this.setPromisifiedState({
        projectRunning: true,
        projectModalIsOpen: false,
      });

      await Request.post(`/locations/transfer/${type}/${target}`, data);

      await this.setPromisifiedState({
        projectRunning: false,
      });
    } catch (error) {
      this.setState({
        projectRunning: false,
        error,
      });
    }
  }

  async deleteJob(job) {
    try {
      await Request.delete(`/locations/${this.state.item._id}/jobs`, job);
      this.loadData();
    } catch (error) {
      this.setState({
        isLoaded: true,
        error,
        redirectList: error.status === 404,
      });
    }
  }

  async addTask(data) {
    try {
      await Request.post(`/locations/${this.state.item._id}/jobs`, data);
      this.closeModal();
      this.loadData();
    } catch (error) {
      this.setState({
        isLoaded: true,
        error,
        redirectList: error.status === 404,
      });
    }
  }

  getPriceForTask(type) {
    if (!this.state.item.price) return null;
    const task = type === 'mb_for_vds' ? 'mb' : type;
    return get(this.state.item, `price.${task}`);
  }

  getCustomer(item) {
    if (!item || !item.customer) return null;
    if (typeof item.customer === 'string') return item.customer;
    return item.customer._id;
  }

  hasPlaned(item) {
    return item && item.nextJob && !!item.nextJob.all;
  }

  render() {
    const {
      item,
      customers,
      customerGroups,
      customerNumbers,
      tasks,
      modalIsOpen,
      projectModalIsOpen,
      filterActive,
    } = this.state;

    const { id } = this.props.match.params;

    const transferTarget = [];
    if (item && item.customer && item.customer._id)
      transferTarget.push({ value: 'customer', text: 'Alle Kunden-Standorte' });
    if (item && item.customer && item.customer.group && item.customer.group._id)
      transferTarget.push({
        value: 'customergroup',
        text: 'Alle Kundengruppe-Standorte',
      });
    if (
      item &&
      item.customer &&
      item.customer.partner &&
      item.customer.partner._id
    )
      transferTarget.push({ value: 'partner', text: 'Alle Partner-Standorte' });

    return (
      <AuthConsumer>
        {({ hasPermission }) => (
          <React.Fragment>
            <div className='location container-inner'>
              {this.state.redirectList ? (
                <Redirect to='/administration/locations' />
              ) : (
                ''
              )}
              <InPageNavigation to='/administration/locations' item={item} />
              <div className='page-header page-header-line row justify-content-between'>
                <div className='col-6 col'>
                  <h1 className='page-title'>
                    {item && item.address ? item.address.street + ' |' : ''}{' '}
                    {item && item.address ? item.address.city + ' |' : ''}{' '}
                    {item && item.customer ? item.customer.name : ''}
                  </h1>
                </div>
                <div className='col-6 col'>
                  <NavLink
                    to={`/administration/locations/${item._id}/history/`}
                    className='delete float-right'
                  >
                    {/* <CheckIcon /> */}
                    Verlauf
                  </NavLink>
                  {hasPermission(['location:write']) === true &&
                  transferTarget.length > 0 ? (
                    <p
                      className='delete float-right'
                      onClick={this.openProjectModal}
                    >
                      <TransferIcon />
                      Übertragen
                    </p>
                  ) : null}
                  {hasPermission(['location:write']) === true &&
                  this.hasPlaned(item) === false ? (
                    <Popconfirm
                      placement='bottomRight'
                      title='Sind Sie sich sicher?'
                      onConfirm={this.remove}
                      okText='Ja'
                      cancelText='Nein'
                    >
                      <p className='delete float-right'>
                        <TrashIcon />
                        Standort löschen
                      </p>
                    </Popconfirm>
                  ) : null}
                  {/* <p
                    className='delete float-right mr-3'
                    onClick={this.openModal}
                  >
                    <PlusIcon />
                    Job anlegen
                  </p> */}
                </div>
              </div>
              <FormConsumer>
                {({ isEditing }) => (
                  <div
                    className={`page-content mb-3 ${
                      isEditing ? 'editing' : ''
                    }`}
                  >
                    <div className='row'>
                      <form className='col col-12 col-xs-6 col-md-6 col-lg-6 col-xl-6'>
                        <div className='row table-divider'>
                          <div className='col'>
                            <span>Stammdaten</span>
                          </div>
                        </div>
                        <div className='row table-row input-row'>
                          <div className='col'>
                            <SelectInput
                              title='Kundengruppe'
                              readOnly={true}
                              value={this.getCustomer(item)}
                              options={customerGroups}
                            />
                          </div>
                        </div>
                        <div className='row table-row input-row'>
                          <div className='col'>
                            <SelectInput
                              title='Kunde'
                              readOnly={
                                hasPermission(['location:write']) !== true
                              }
                              value={this.getCustomer(item)}
                              options={customers}
                              callback={(value) => this.save('customer', value)}
                            />
                          </div>
                        </div>
                        <div className='row table-row input-row'>
                          <div className='col'>
                            <SelectInput
                              title='Kundennummer'
                              readOnly={true}
                              value={this.getCustomer(item)}
                              options={customerNumbers}
                            />
                          </div>
                        </div>
                        <div className='row table-row input-row'>
                          <div className='col'>
                            <EditableInput
                              title='Name'
                              value={item.name}
                              callback={(value) => this.save('name', value)}
                            />
                          </div>
                        </div>
                        <div className='row table-row input-row'>
                          <div className='col'>
                            <EditableInput
                              title='Standort NR'
                              value={item.tag}
                              callback={(value) => this.save('tag', value)}
                            />
                          </div>
                        </div>
                        <div className='row table-row input-row'>
                          <div className='col'>
                            <EditableInput
                              title='Ansprechpartner'
                              readOnly={
                                hasPermission(['location:write']) !== true
                              }
                              value={item.contact}
                              validate={(value) =>
                                value !== undefined &&
                                value !== null &&
                                value.length > 2
                              }
                              callback={(value) => this.save('contact', value)}
                            />
                          </div>
                        </div>
                        <div className='row table-row input-row'>
                          <div className='col'>
                            <EditableInput
                              title='E-Mail'
                              readOnly={
                                hasPermission(['location:write']) !== true
                              }
                              value={item.email}
                              type='email'
                              // validate={value => value && EMAIL_REGEXP.exec(value) !== null}
                              callback={(value) => this.save('email', value)}
                            />
                          </div>
                        </div>
                        <div className='row table-row input-row'>
                          <div className='col'>
                            <EditableInput
                              title='E-Mail 2'
                              readOnly={
                                hasPermission(['location:write']) !== true
                              }
                              value={item.email2}
                              type='email'
                              // validate={value => value && EMAIL_REGEXP.exec(value) !== null}
                              callback={(value) => this.save('email2', value)}
                            />
                          </div>
                        </div>
                        <div className='row table-row input-row'>
                          <div className='col'>
                            <EditableInput
                              title='Telefon'
                              readOnly={
                                hasPermission(['location:write']) !== true
                              }
                              value={item.phone}
                              validate={(value) =>
                                value !== undefined &&
                                value !== null &&
                                value.length > 2
                              }
                              callback={(value) => this.save('phone', value)}
                            />
                          </div>
                        </div>
                        <div className='row table-row input-row'>
                          <div className='col'>
                            <EditableInput
                              title='Telefon 2'
                              readOnly={
                                hasPermission(['location:write']) !== true
                              }
                              value={item.phone2}
                              validate={(value) =>
                                value !== undefined &&
                                value !== null &&
                                value.length > 2
                              }
                              callback={(value) => this.save('phone2', value)}
                            />
                          </div>
                        </div>
                        <div className='row table-row input-row'>
                          <div className='col'>
                            <EditableInput
                              title='Fax'
                              readOnly={
                                hasPermission(['location:write']) !== true
                              }
                              value={item.fax}
                              validate={(value) =>
                                value !== undefined &&
                                value !== null &&
                                value.length > 2
                              }
                              callback={(value) => this.save('fax', value)}
                            />
                          </div>
                        </div>
                        <div className='row table-row input-row'>
                          <div className='col'>
                            <EditableInput
                              title='Fläche'
                              type='number'
                              value={item.space}
                              readOnly={
                                hasPermission(['location:write']) !== true
                              }
                              callback={(value) => this.save('space', value)}
                            />
                          </div>
                        </div>
                        <div className='row table-divider'>
                          <div className='col'>
                            <span>Benachrichtigungseinstellungen</span>
                          </div>
                        </div>
                        <div className='row table-row input-row'>
                          <div className='col'>
                            <SelectInput
                              title='Design'
                              value={item.shareType}
                              options={[
                                { value: 'datetime', text: 'Datum & Uhrzeit' },
                                { value: 'date', text: 'Datum' },
                                { value: 'kw', text: 'Kalenderwoche' },
                              ]}
                              readOnly={true}
                            />
                          </div>
                        </div>
                        <div className='row table-divider'>
                          <div className='col'>
                            <span>Sonstiges</span>
                          </div>
                        </div>
                        <div className='row table-row input-row'>
                          <div className='col'>
                            <EditableInput
                              title='Techniker Info'
                              value={item.comment}
                              defaultValue={
                                item.customer ? item.customer.comment : null
                              }
                              readOnly={
                                hasPermission(['location:write']) !== true
                              }
                              callback={(value) => this.save('comment', value)}
                            />
                          </div>
                        </div>
                        <div className='row table-row input-row'>
                          <div className='col'>
                            <EditableInput
                              title='Planer Info'
                              value={item.note}
                              defaultValue={'Wird nur in der Planung angezeigt'}
                              readOnly={
                                hasPermission(['location:write']) !== true
                              }
                              callback={(value) => this.save('note', value)}
                            />
                          </div>
                        </div>
                        <div className='row table-row input-row'>
                          <div className='col'>
                            <EditableInput
                              title='Prüfzeiten'
                              value={item.openingHours}
                              callback={(value) =>
                                this.save('openingHours', value)
                              }
                            />
                          </div>
                        </div>
                        <div className='row table-row input-row'>
                          <div className='col'>
                            <EditableInput
                              title='Area/KS'
                              value={item.area_ks}
                              readOnly={
                                hasPermission(['location:write']) !== true
                              }
                              callback={(value) => this.save('area_ks', value)}
                            />
                          </div>
                        </div>
                      </form>
                      <div className='col col-12 col-xs-6 col-md-6 col-lg-6 col-xl-6'>
                        <div className='row table-divider'>
                          <div className='col'>
                            <span>Adresse</span>
                            <CopyButton
                              data={`${item.address?.street ?? ''} ${
                                item.address?.postalCode ?? ''
                              } ${item.address?.city ?? ''} ${
                                item.address?.countryCode ?? ''
                              }`}
                            ></CopyButton>
                          </div>
                        </div>
                        <div className='row table-row input-row'>
                          <div className='col'>
                            <EditableInput
                              title='Straße'
                              value={item.address?.street}
                              readOnly={
                                hasPermission(['location:write']) !== true
                              }
                              validate={(value) =>
                                value !== undefined &&
                                value !== null &&
                                value.length > 1
                              }
                              callback={(value) =>
                                this.save('address', {
                                  ...item.address,
                                  street: value,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className='row table-row input-row'>
                          <div className='col'>
                            <EditableInput
                              title='Stadt'
                              value={item.address?.city}
                              readOnly={
                                hasPermission(['location:write']) !== true
                              }
                              validate={(value) =>
                                value !== undefined &&
                                value !== null &&
                                value.length > 1
                              }
                              callback={(value) =>
                                this.save('address', {
                                  ...item.address,
                                  city: value,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className='row table-row input-row'>
                          <div className='col'>
                            <EditableInput
                              title='Plz'
                              value={item.address?.postalCode}
                              readOnly={
                                hasPermission(['location:write']) !== true
                              }
                              validate={(value) =>
                                value !== undefined &&
                                value !== null &&
                                value.length > 3 &&
                                value.length < 6
                              }
                              callback={(value) =>
                                this.save('address', {
                                  ...item.address,
                                  postalCode: value,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className='row table-row input-row'>
                          <div className='col'>
                            <EditableInput
                              title='Land'
                              value={item.address?.countryCode}
                              readOnly={
                                hasPermission(['location:write']) !== true
                              }
                              validate={(value) =>
                                value !== undefined &&
                                value !== null &&
                                value.length > 2
                              }
                              callback={(value) =>
                                this.save('address', {
                                  ...item.address,
                                  countryCode: value,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className='row table-divider'>
                          <div className='col'>
                            <span>Adresse Navigation</span>
                            <CopyButton
                              data={`${item.addressRouting?.street ?? ''} ${
                                item.addressRouting?.postalCode ?? ''
                              } ${item.addressRouting?.city ?? ''} ${
                                item.addressRouting?.countryCode ?? ''
                              }`}
                            ></CopyButton>
                          </div>
                        </div>
                        <div className='row input-row'>
                          <div className='col'>
                            <MapInput
                              force={true}
                              className='small'
                              readOnly={
                                hasPermission(['location:write']) !== true
                              }
                              value={item.addressRouting}
                              callback={(value) =>
                                this.save('addressRouting', value)
                              }
                            />
                          </div>
                        </div>
                        <div className='row table-divider'>
                          <div className='col'>
                            <span>
                              Einheiten
                              <small className='ml-1'>
                                (Boxen, Kammern, Abteilungen, Konzessionen,
                                Bereiche, Werkbänke, etc.)
                              </small>
                            </span>
                          </div>
                        </div>
                        <div className='row table-row input-row'>
                          <div className='col'>
                            <EditableInput
                              title='Anzahl'
                              type='number'
                              value={item.basisItemCount}
                              readOnly={
                                hasPermission(['location:write']) !== true
                              }
                              callback={(value) =>
                                this.save('basisItemCount', value)
                              }
                            />
                          </div>
                        </div>
                        <div className='row table-divider'>
                          <div className='col'>
                            <span>Label</span>
                          </div>
                        </div>
                        <div className='row table-row input-row'>
                          <div className='col-5 pr-0'>
                            <CheckInput
                              // short='Am'
                              title='geprüft am'
                              value={item.labelCheckedAt}
                              callback={(value) =>
                                this.save('labelCheckedAt', value)
                              }
                            />
                          </div>
                          <div className='col-4 pl-0'>
                            <CheckInput
                              // short='Nch'
                              title='nächste Prüfung'
                              value={item.labelNextCheckedAt}
                              callback={(value) =>
                                this.save('labelNextCheckedAt', value)
                              }
                            />
                          </div>
                        </div>
                        <div className='row table-divider'>
                          <div className='col'>
                            <span>Sperre</span>
                          </div>
                        </div>
                        <div className='row table-row input-row'>
                          <div className='col-5 pr-0'>
                            <CheckInput
                              title='für Planung sperren'
                              value={item.locked_planing}
                              callback={(value) =>
                                this.save('locked_planing', value)
                              }
                            />
                          </div>
                        </div>
                        <div className='row table-divider'>
                          <div className='col'>
                            <span>VDS</span>
                          </div>
                        </div>
                        <div className='row table-row input-row'>
                          <div className='col-5 pr-0'>
                            <CheckInput
                              title='Berichte vorhanden'
                              value={item.vds_report}
                              callback={(value) =>
                                this.save('vds_report', value)
                              }
                            />
                          </div>
                        </div>
                        <div className='row table-divider'>
                          <div className='col'>
                            <span>Dateien</span>
                          </div>
                        </div>
                        <div className='row table-row input-row'>
                          <div className='col-12'>
                            <Files
                              location={item}
                              onChanged={() => this.loadData()}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='row mb-3'>
                      <div className='col col-12'>
                        <div className='row table-divider'>
                          <div className='col'>
                            <span>Kombipreis</span>
                          </div>
                        </div>
                      </div>
                      <div className='col col-12'>
                        <div className='row table-row input-row'>
                          <div className='col-3 pr-0'>
                            <EditableInput
                              title='Preis'
                              type='number'
                              value={item.priceCombi}
                              readOnly={
                                hasPermission(['location:write']) !== true
                              }
                              callback={(value) =>
                                this.save('priceCombi', value)
                              }
                            />
                          </div>
                        </div>
                        <div className='row table-row input-row mb-1'>
                          {tasks.map((task) => (
                            <div key={task.key} className='col-2 pr-0'>
                              <CheckInput
                                title={task.title}
                                value={
                                  item.priceCombiCondition
                                    ? item.priceCombiCondition[task.key]
                                    : false
                                }
                                callback={(value) =>
                                  this.save(
                                    `priceCombiCondition.${task.key}`,
                                    value
                                  )
                                }
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    {/* <Appointments location={id} /> */}
                    <Contracts location={id} />
                    {item && (
                      <Fragment>
                        <div className='row mb-3'>
                          <div className='col col-12'>
                            <div className='row table-divider'>
                              <div className='col'>
                                <span>Prüfungen</span>
                              </div>
                              <div className='col'>
                                <div className='float-right'>
                                  <Switch
                                    checked={!filterActive}
                                    title='Bearbeiten'
                                    onChange={(checked) =>
                                      this.setState({ filterActive: !checked })
                                    }
                                  />
                                  <span
                                    style={{
                                      marginLeft: '8px',
                                    }}
                                  >
                                    Alle anzeigen
                                  </span>
                                </div>
                              </div>
                            </div>
                            {tasks.map((task) =>
                              renderTask(
                                task,
                                item,
                                false,
                                isEditing,
                                this.save,
                                filterActive
                              )
                            )}
                          </div>
                        </div>
                        {/* <div className='row mb-3'>
                          <div className='col col-12'>
                            <div className='row table-divider'>
                              <div className='col'>
                                <span>Zusätzliche Jobs</span>
                              </div>
                            </div>
                            {chain(item.additionalTasks)
                              .orderBy('due', 'desc')
                              .map((task) =>
                                renderAdditionalTasks(
                                  task,
                                  item,
                                  this.removeAdditionalTask
                                )
                              )
                              .value()}
                          </div>
                        </div> */}
                      </Fragment>
                    )}
                    <LocationJobs
                      location={item._id}
                      onDeleteJob={(job) => {
                        this.deleteJob(job);
                      }}
                    />
                  </div>
                )}
              </FormConsumer>
            </div>
            <AddTaskModal
              isOpen={modalIsOpen}
              close={this.closeModal}
              getPriceForTask={this.getPriceForTask}
              onSubmit={this.addTask}
              taskTypes={[
                {
                  value: 'mb_for_vds',
                  text: 'MB für VDS',
                },
                ...tasks.map((task) => {
                  return {
                    value: task.key,
                    text: task.title,
                  };
                }),
              ]}
            />
            {item && transferTarget.length > 0 ? (
              <Fragment>
                <TransferModal
                  isOpen={projectModalIsOpen}
                  close={this.closeProjectModal}
                  transferTarget={transferTarget}
                  transferable={[
                    {
                      value: 'contact',
                      text: 'Ansprechpartner',
                    },
                    {
                      value: 'comment',
                      text: 'Techniker Info',
                    },
                    {
                      value: 'priceCombi',
                      text: 'Kombipreis',
                    },
                    {
                      value: 'labelCheckedAt',
                      text: 'Label "geprüft am"',
                    },
                    {
                      value: 'labelNextCheckedAt',
                      text: 'Label "Nächste Prüfung"',
                    },
                    {
                      value: 'locked_planing',
                      text: 'Sperre',
                    },
                    ...tasks.map((task) => {
                      return {
                        value: `task.${task.key}`,
                        text: task.title,
                      };
                    }),
                  ]}
                  onSubmit={this.project}
                />
                <TransferModalLoading isOpen={this.state.projectRunning} />
              </Fragment>
            ) : null}
          </React.Fragment>
        )}
      </AuthConsumer>
    );
  }
}

export default LocationEdit;
