import './UploadInput.scss';

import React, { Component } from 'react';

import { FormConsumer } from '../../context/FormContext';
import { ReactComponent as TrashIcon } from '../../assets/icons/trash.svg';
import { ReactComponent as UploadIcon } from '../../assets/icons/upload.svg';
import { get } from 'lodash';
import { v4 as uuid } from 'uuid';

class UploadInput extends Component {
  elementID = uuid();

  state = {
    editing: false,
    value: '',
    accept: null,
    valid: true,
    readOnly: false,
    status: 0,
  };

  constructor(props) {
    super(props);
    if (props.value) this.state.value = props.value;
    if (props.accept) this.state.accept = props.accept;
    if (props.readOnly) this.state.readOnly = props.readOnly;
    this.handleChange = this.handleChange.bind(this);
    this.setResultStatus = this.setResultStatus.bind(this);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.setState({
      value: newProps.value || '',
      accept: newProps.accept || null,
      readOnly: newProps.readOnly || false,
    });
  }

  setResultStatus(result) {
    this.setState({ status: result ? 0 : 2 });
  }

  handleChange(event) {
    const file = get(event, 'target.files[0]');
    if (file && (!this.state.accept || file.type === this.state.accept)) {
      this.setState({ status: 1 });
      this.props.callback(file, this.setResultStatus);
    }
  }

  empty() {
    this.setState({ status: 3 });
    this.props.callback(null, this.setResultStatus);
  }

  getText(originalname, status) {
    if (status === 1) return 'Hochladen...';
    if (status === 2) return 'Fehler';
    if (status === 3) return 'Löschen...';
    return originalname ? originalname : '';
  }

  _getInput(state) {
    const { value, status } = state;
    const { originalname } = value;
    return (
      <input
        ref={(input) => {
          this.refInput = input;
        }}
        id={`upload-input-${this.elementID}`}
        placeholder={this.props.placeholder}
        disabled={true}
        type='text'
        name='name'
        value={this.getText(originalname, status)}
      />
    );
  }

  _getFileInput(state) {
    const { accept } = state;
    return (
      <input
        ref={(input) => {
          this.refFileInput = input;
        }}
        id={`upload-input-${this.elementID}-file`}
        type='file'
        name='file'
        accept={accept}
        className='hidden'
        onChange={this.handleChange}
      />
    );
  }

  render() {
    const { title } = this.props;
    const { value, editing } = this.state;
    return (
      <FormConsumer>
        {() => (
          <div className={`upload-input ${editing ? 'editing' : ''}`}>
            <label htmlFor={`upload-input-${this.elementID}`}>{title}</label>
            {this._getInput(this.state)}
            {this._getFileInput(this.state)}
            {this.state.readOnly !== true ? (
              <div className='actions'>
                <label htmlFor={`upload-input-${this.elementID}-file`}>
                  <UploadIcon className='save' />
                </label>
                <TrashIcon
                  className={`cancel ${value ? '' : 'hidden'}`}
                  onClick={() => {
                    this.empty();
                  }}
                />
              </div>
            ) : (
              ''
            )}
          </div>
        )}
      </FormConsumer>
    );
  }
}

export default UploadInput;
