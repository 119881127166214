import './Technician.scss';

import { NavLink, RouteComponentProps, withRouter } from 'react-router-dom';
import React, { Component } from 'react';
import Table, { SELECTION_TYPE, TableOptions } from '../Table/Table';
import { debounce, filter, pick } from 'lodash';

import { AuthConsumer } from '../../context/AuthContext';
import { FormatValue } from '../Planing/Steps/createLocationTableHeader';
import { ReactComponent as PlusIcon } from '../../assets/icons/plus.svg';
import { Request } from '../../api/Request';
import Search from '../Search/Search';
import { hasPermissionFunc } from '../../context/hasPermissionFunc';
import qs from 'query-string';
import {
  FILTER_OPERATOR_EQ_BOOL,
  FILTER_OPERATOR_EQ_NUMBER,
} from '../Table/Filter';

const defaultOptions = {
  sort: 'initials',
  desc: false,
  page: 0,
  limit: 25,
  fuzzy: true,
  filters: {
    locked_planing: [
      {
        value: 'selected',
        comparator: '!=B',
      },
    ],
  },
};

type Technician = any;

interface MatchParams {
  id: string;
}

interface TechnicianListProps extends RouteComponentProps<MatchParams> {}

interface TechnicianListOptions extends TableOptions {
  q?: string | null;
  fuzzy?: boolean;
}

interface TechnicianListState {
  error?: Error;
  loading: boolean;
  searching: boolean;
  options: TechnicianListOptions;
  items: Technician[];
  selectedItems: Technician[];
  maxItems?: number;
}

class TechnicianList extends Component<
  TechnicianListProps,
  TechnicianListState
> {
  state: TechnicianListState = {
    loading: true,
    searching: false,
    options: defaultOptions,
    maxItems: 0,
    items: [],
    selectedItems: [],
  };

  handleSearchDebounced = debounce(this.handleSearch.bind(this), 440);

  header(searching: boolean) {
    return [
      {
        key: 'initials',
        width: '0-5',
        sort: searching ? false : true,
        title: 'Kürzel',
      },
      {
        key: 'name',
        width: '1-25',
        sort: searching ? false : true,
        title: 'Name',
      },
      {
        key: 'address.street',
        width: 2,
        sort: searching ? false : true,
        title: 'Straße',
      },
      {
        key: 'address.postalCode',
        width: 1,
        sort: searching ? false : true,
        title: 'PLZ',
      },
      {
        key: 'address.city',
        width: 2,
        sort: searching ? false : true,
        title: 'Ort',
      },
      {
        key: 'phone',
        width: '1-5',
        sort: searching ? false : true,
        title: 'Telefon',
      },
      {
        key: 'email',
        width: 2,
        sort: searching ? false : true,
        title: 'E-Mail',
      },
      {
        key: 'analysis_group',
        width: '0-5',
        sort: false,
        title: 'Ausw.',
      },
      {
        key: 'locked_planing',
        width: '0-5',
        sort: searching ? false : false,
        title: 'Gesperrt',
        filterable: true,
        filterComparators: FILTER_OPERATOR_EQ_BOOL,
        format: (value: FormatValue) => {
          return <p>{value ? 'Ja' : 'Nein'}</p>;
        },
      },
    ];
  }

  constructor(props: TechnicianListProps) {
    super(props);
    this.handleOptionsChanged = this.handleOptionsChanged.bind(this);
    this.handleSelectionChanged = this.handleSelectionChanged.bind(this);
    this.removeSelectedTechnicians = this.removeSelectedTechnicians.bind(this);
  }

  async componentDidMount() {
    await this.loadOptionsFromURL(this.props.location);
    this.loadData();
  }

  loadOptionsFromURL(location: { search: string }) {
    const data = qs.parse(location.search);
    return new Promise<void>((resolve) => {
      const s: TechnicianListOptions = {
        filters:
          data && data.filters
            ? JSON.parse(data.filters as string)
            : defaultOptions.filters,
      };
      s.sort = (data.sort as string) || defaultOptions.sort;
      s.page = Number(data.page) || defaultOptions.page;
      s.desc = Boolean(data.desc) || defaultOptions.desc;
      s.limit = Number(data.limit) || defaultOptions.limit;
      s.q = data.q as string;
      s.fuzzy = data.fuzzy ? data.fuzzy === 'true' : defaultOptions.fuzzy;
      this.setState({ options: s }, async () => resolve());
    });
  }

  storeOptionsAtURL() {
    const s: any = pick(this.state.options, [
      'sort',
      'desc',
      'page',
      'limit',
      'q',
      'fuzzy',
    ]);
    s.filters = JSON.stringify(this.state.options.filters ?? {});

    const stringified = qs.stringify(s);
    window.history.replaceState(s, 'safePlan', `?${stringified}`);
  }

  async handleSearch(query: string | undefined, fuzzy: boolean) {
    this.setState(
      {
        loading: true,
        options: {
          limit: undefined,
          page: undefined,
          desc: undefined,
          sort: 'score',
          q: query,
          fuzzy,
        },
      },
      () => {
        this.storeOptionsAtURL();
        this.loadData();
      }
    );
  }

  async loadData() {
    const s: any = pick(this.state.options, [
      'sort',
      'desc',
      'page',
      'limit',
      'q',
      'fuzzy',
    ]);

    if (
      this.state.options.filters?.locked_planing !== undefined &&
      this.state.options.filters?.locked_planing.length > 0
    ) {
      s.locked_planing =
        this.state.options.filters.locked_planing[0].comparator === '=B';
    }

    const result: {
      search: boolean;
      items: any[];
      total: number;
    } = await Request.list('technicians', s);
    this.setState({
      loading: false,
      searching: result.search,
      items: result.items,
      maxItems: result.search ? 0 : result.total,
    });
  }

  handleOptionsChanged(opts: TechnicianListOptions) {
    this.setState({ loading: true, options: opts }, () => {
      this.storeOptionsAtURL();
      this.loadData();
    });
  }

  handleSelectionChanged(techniciansToSet: Technician[], selection: boolean) {
    const { selectedItems } = this.state;
    if (selection) {
      this.setState({
        selectedItems: selectedItems.concat(techniciansToSet),
      });
    } else {
      const ids = techniciansToSet.map((l) => l._id);
      const value = filter(selectedItems, (l) => !ids.includes(l._id));
      this.setState({ selectedItems: value });
    }
  }

  async removeSelectedTechnicians() {
    // eslint-disable-next-line no-restricted-globals
    if (confirm('Sind Sie sich sicher?')) {
      try {
        this.setState({ loading: true });
        const { selectedItems } = this.state;
        await Promise.all(
          selectedItems.map((s) => {
            return Request.delete('technicians', s._id);
          })
        );
        this.loadData();
      } catch (error: any) {
        this.setState({
          loading: false,
          error,
        });
      }
    }
  }

  render() {
    const { items, options, maxItems, selectedItems, loading } = this.state;
    return (
      <AuthConsumer>
        {({ hasPermission }: { hasPermission: hasPermissionFunc }) => (
          <div className='technician container-inner container-inner-list'>
            <div className='page-header row justify-content-between'>
              <div className='col col-12 col-md-6'>
                <h1 className='page-title'>Techniker verwalten</h1>
              </div>
              <div className='col col-12 col-md-6 page-header-actions justify-content-md-end pt-md-0'>
                <Search
                  value={options.q}
                  onChange={this.handleSearchDebounced}
                />
                {hasPermission(['technician:write']) === true ? (
                  <NavLink
                    className='float-right'
                    to={`/administration/technicians/new`}
                  >
                    <PlusIcon />
                    Hinzufügen
                  </NavLink>
                ) : null}
                {/* {hasPermission(['technician:delete-multi']) === true &&
                selectedItems.length > 0 ? (
                  <div onClick={this.removeSelectedTechnicians}>
                    <TrashIcon />
                    <p>Ausgewählte löschen</p>
                  </div>
                ) : null} */}
              </div>
            </div>
            <div className='row page-content'>
              <div className='col col-12'>
                <Table
                  loading={loading}
                  filterable={true}
                  selectable={SELECTION_TYPE.disabled}
                  options={options}
                  header={this.header(this.state.searching)}
                  items={items}
                  maxItems={maxItems}
                  handleOptionsChanged={this.handleOptionsChanged}
                  selection={selectedItems}
                  handleSelectionChanged={this.handleSelectionChanged}
                  link={'/administration/technicians'}
                />
              </div>
            </div>
          </div>
        )}
      </AuthConsumer>
    );
  }
}

export default withRouter(TechnicianList);
