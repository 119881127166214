import '../Filter.scss';
import './index.scss';

import { Button, Col, Row, notification } from 'antd';
import { debounce, get, map, pick, set, uniq, uniqBy } from 'lodash';
import React, { Component, Fragment } from 'react';
import { NavLink, RouteComponentProps } from 'react-router-dom';

import download from 'downloadjs';
import { DateTime } from 'luxon';
import qs from 'query-string';
import { Request } from '../../../api/Request';
import { ReactComponent as CommentIcon } from '../../../assets/icons/comment.svg';
import { ReactComponent as _CopyIcon } from '../../../assets/icons/copy.svg';
import { ReactComponent as CustomerIcon } from '../../../assets/icons/customer.svg';
import { ReactComponent as DownloadIcon } from '../../../assets/icons/download.svg';
import { ReactComponent as NoteIcon } from '../../../assets/icons/info1.svg';
import { ReactComponent as MapIcon } from '../../../assets/icons/pin.svg';
import { ReactComponent as PrintIcon } from '../../../assets/icons/printer.svg';
import { ReactComponent as TechIcon } from '../../../assets/icons/technician2.svg';
import { ReactComponent as TourIcon } from '../../../assets/icons/tours.svg';

import {
  ReactComponent as EmailIcon,
  ReactComponent as FaxIcon,
  ReactComponent as PhoneIcon,
} from '../../../assets/icons/info1.svg';

import { TableOptions } from '../../Table/Table';
import DateFilter from '../DateFilter';
import Filter from '../Filter';

import { withRouter } from 'react-router';
import styled from 'styled-components';
import { DateValue } from '../../Picker/DatePicker';
import StatusAction, {
  Status,
} from '../../common/CardList/Actions/StatusAction';
import CardList from '../../common/CardList/CardList';
import CardListItem from '../../common/CardList/CardListItem';

const CopyIcon = styled(_CopyIcon)`
  fill: #009842;
  color: #009842;
  width: 16px;
  height: 16px;
  cursor: pointer;
  margin-left: 0.5rem !important;
`;

const FilterWrapper = styled.div`
  > div {
    margin: 0 1rem 1rem 0;
  }
`;

function parseDate(startDate: DateValue) {
  return typeof startDate === 'string'
    ? DateTime.fromISO(startDate)
    : DateTime.fromJSDate(startDate).setLocale('de');
}

const formatAddress = (item: any) =>
  [
    get(item, 'address.street', '').trim(),
    `${get(item, 'address.postalCode', '').trim()} ${get(
      item,
      'address.city',
      ''
    ).trim()}`,
    `${get(item, 'address.countryCode', '').trim()}`,
  ].join(', ');

export const defaultOptions = {
  sort: 'address.postalCode',
  desc: false,
  page: 0,
  limit: 2500,
  dateRange: {
    startDate: DateTime.fromJSDate(new Date()).startOf('week').toJSDate(),
    endDate: DateTime.fromJSDate(new Date()).endOf('week').toJSDate(),
  },
};

export interface ToursLocationsListOptions extends TableOptions {
  dateRange?: {
    startDate: Date;
    endDate: Date;
  };
  customers?: any[];
  technicians?: any[];
  cities?: any[];
}

export type PlanedLocationJob = any;

export type PlanedLocation = {
  jobs: PlanedLocationJob[];
} & any;

interface ToursLocationsListState {
  loading: boolean;
  download: boolean;
  loadingFilters: boolean;
  items: PlanedLocation[];
  options: ToursLocationsListOptions;
  filterValues: {
    customers: any[];
    technicians: any[];
    cities: any[];
  };
}

class ToursLocationsList extends Component<
  RouteComponentProps,
  ToursLocationsListState
> {
  state: ToursLocationsListState = {
    loading: true,
    download: false,
    loadingFilters: true,
    items: [],
    options: defaultOptions,
    filterValues: {
      customers: [],
      technicians: [],
      cities: [],
    },
  };

  storeOptionsAndLoadDebounced: () => void;

  constructor(props: RouteComponentProps) {
    super(props);
    this.handleOptionsChanged = this.handleOptionsChanged.bind(this);
    this.downloadExport = this.downloadExport.bind(this);
    this.storeOptionsAndLoadDebounced = debounce(this.storeOptionsAndLoad, 500);
  }

  setPromisifiedState(data: any) {
    return new Promise<void>((resolve) => this.setState(data, () => resolve()));
  }

  async componentDidMount() {
    await this.loadOptionsFromURL(this.props.location);
    this.storeOptionsAndLoad();
  }

  storeOptionsAndLoad() {
    this.storeOptionsAtURL();
    this.loadData();
  }

  dateFromMillis(m: string | number) {
    return DateTime.fromMillis(Number(m)).toJSDate();
  }

  loadOptionsFromURL(location: { search: string }) {
    const data = qs.parse(location.search);
    return new Promise<void>((resolve) => {
      const s: ToursLocationsListOptions = {};
      s.sort = (data.sort as string) || defaultOptions.sort;
      s.page = Number(data.page) || defaultOptions.page;
      s.desc = Boolean(data.desc) || defaultOptions.desc;
      s.limit = Number(data.limit) || defaultOptions.limit;
      s.customers = (data.customers as string[]) || undefined;
      s.technicians = (data.technicians as string[]) || undefined;
      s.cities = (data.cities as string[]) || undefined;
      s.dateRange = {
        startDate: data.startDate
          ? this.dateFromMillis(data.startDate as string)
          : defaultOptions.dateRange.startDate,
        endDate: data.endDate
          ? this.dateFromMillis(data.endDate as string)
          : defaultOptions.dateRange.endDate,
      };
      this.setState({ options: s }, async () => resolve());
    });
  }

  storeOptionsAtURL() {
    let s: Pick<
      ToursLocationsListOptions,
      | 'sort'
      | 'desc'
      | 'page'
      | 'limit'
      | 'customers'
      | 'technicians'
      | 'cities'
    > & {
      startDate?: number;
      endDate?: number;
    } = pick(this.state.options, [
      'sort',
      'desc',
      'page',
      'limit',
      'customers',
      'technicians',
      'cities',
    ]);

    const _start = get(this.state, 'options.dateRange.startDate');
    const _end = get(this.state, 'options.dateRange.endDate');

    const startDate =
      _start !== undefined ? DateTime.fromJSDate(_start) : undefined;
    const endDate = _end !== undefined ? DateTime.fromJSDate(_end) : undefined;

    s = {
      ...s,
      startDate:
        startDate !== undefined && startDate.isValid
          ? startDate.toMillis()
          : undefined,
      endDate:
        endDate !== undefined && endDate.isValid
          ? endDate.toMillis()
          : undefined,
    };

    const stringified = qs.stringify(s);
    window.history.replaceState(s, 'safePlan', `?${stringified}`);
  }

  async loadData() {
    let s: Pick<
      ToursLocationsListOptions,
      | 'sort'
      | 'desc'
      | 'page'
      | 'limit'
      | 'customers'
      | 'technicians'
      | 'cities'
    > & {
      startDate?: string;
      endDate?: string;
    } = pick(this.state.options, [
      'sort',
      'desc',
      'page',
      'limit',
      'customers',
      'technicians',
      'cities',
    ]);

    const _start = get(this.state, 'options.dateRange.startDate');
    const _end = get(this.state, 'options.dateRange.endDate');

    const startDate =
      _start !== undefined ? DateTime.fromJSDate(_start) : undefined;
    const endDate = _end !== undefined ? DateTime.fromJSDate(_end) : undefined;

    s = {
      ...s,
      startDate:
        startDate !== undefined && startDate.isValid
          ? startDate.toISO()
          : undefined,
      endDate:
        endDate !== undefined && endDate.isValid ? endDate.toISO() : undefined,
    };

    const result: {
      filtered: any[];
      unfilterd: any[];
    } = await Request.list('locationsWithJob', s);

    const uniqLocations = uniqBy(result.unfilterd, '_id');

    const mappedCustomers = uniqBy(
      uniqLocations.map((l: any) => {
        return {
          key: l.customer._id,
          value: l.customer._id,
          text: l.customer.name,
        };
      }),
      'value'
    );

    const mappedTechnicians = uniqBy(
      uniqLocations.map((l: any) => {
        return {
          key: get(l, 'jobs[0].technician._id', ''),
          value: get(l, 'jobs[0].technician._id', ''),
          text: get(l, 'jobs[0].technician.name', ''),
        };
      }),
      'value'
    );

    const mappedCities = uniqBy(
      uniqLocations.map((l: any) => {
        return {
          key: l.address.city,
          value: l.address.city,
          text: l.address.city,
        };
      }),
      'value'
    );

    this.setState({
      loading: false,
      items: result.filtered,
      filterValues: {
        customers: mappedCustomers,
        technicians: mappedTechnicians,
        cities: mappedCities,
      },
    });
  }

  handleOptionsChanged(opts: ToursLocationsListOptions) {
    this.setState({ loading: true, options: opts }, () => {
      this.storeOptionsAtURL();
      this.loadData();
    });
  }

  renderFilter() {
    const {
      options,
      filterValues: { customers, technicians, cities },
    } = this.state;
    return (
      <FilterWrapper>
        <DateFilter
          text='Zeitraum'
          range={
            options.dateRange
              ? {
                  startDate: options.dateRange.startDate,
                  endDate: options.dateRange.endDate,
                }
              : undefined
          }
          onChange={(value) => {
            this.handleOptionsChanged({
              dateRange: value
                ? {
                    startDate: parseDate(value.startDate).toJSDate(),
                    endDate: parseDate(value.endDate!).toJSDate(),
                  }
                : undefined,
            });
          }}
        />
        <Filter
          name='customers'
          options={options}
          values={customers}
          onChange={(options: ToursLocationsListOptions) =>
            this.handleOptionsChanged(options)
          }
        >
          Kunden
        </Filter>
        <Filter
          name='technicians'
          options={options}
          values={technicians}
          onChange={(options: ToursLocationsListOptions) =>
            this.handleOptionsChanged(options)
          }
        >
          Techniker
        </Filter>
        <Filter
          name='cities'
          options={options}
          values={cities}
          onChange={(options: ToursLocationsListOptions) =>
            this.handleOptionsChanged(options)
          }
        >
          Städte
        </Filter>
      </FilterWrapper>
    );
  }

  async saveLocation(_id: string, k: 'comment' | 'note', value: string) {
    const data = set({}, k, value);
    Request.put('locations', _id, data).then(
      () => {
        this.loadData();
      },
      () => {
        notification.error({
          message: 'Fehler beim Speichern',
          description: 'Die Änderung konnte nicht gespeichert werden.',
        });
      }
    );
  }

  renderEmpty() {
    return (
      <div className='col col-12 col-xl-6'>
        <p>Es wurden noch keine Touren geplant für diesen Zeitraum.</p>
      </div>
    );
  }

  async downloadExport() {
    await this.setPromisifiedState({ download: true });

    let s: Pick<
      ToursLocationsListOptions,
      'sort' | 'desc' | 'page' | 'limit'
    > & {
      startDate?: string;
      endDate?: string;
    } = pick(this.state.options, [
      'sort',
      'desc',
      'page',
      'limit',
      'customers',
      'technicians',
      'cities',
    ]);

    const _start = get(this.state, 'options.dateRange.startDate');
    const _end = get(this.state, 'options.dateRange.endDate');

    const startDate =
      _start !== undefined ? DateTime.fromJSDate(_start) : undefined;
    const endDate = _end !== undefined ? DateTime.fromJSDate(_end) : undefined;

    s = {
      ...s,
      startDate:
        startDate !== undefined && startDate.isValid
          ? startDate.toISO()
          : undefined,
      endDate:
        endDate !== undefined && endDate.isValid ? endDate.toISO() : undefined,
    };

    const { base64 } = await Request.list('locationsWithJob/export', s);

    const start = this.state.options.dateRange?.startDate;
    const dt = DateTime.fromJSDate(start || new Date()).setLocale('de');
    const asKW = dt.toFormat("yyyy-'KW'WW");
    const stand = DateTime.local().toFormat('yyyy-LL-dd-HH-mm');
    const outFileName = `safeplan-export-pl-${asKW}-stand-${stand}.xlsx`;

    download(
      base64,
      outFileName,
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    );

    await this.setPromisifiedState({ download: false });
  }

  getPrintUrl() {
    let s: Pick<
      ToursLocationsListOptions,
      'sort' | 'desc' | 'page' | 'limit'
    > & {
      startDate?: number;
      endDate?: number;
    } = pick(this.state.options, [
      'sort',
      'desc',
      'page',
      'limit',
      'customers',
      'technicians',
      'cities',
    ]);

    const _start = get(this.state, 'options.dateRange.startDate');
    const _end = get(this.state, 'options.dateRange.endDate');

    const startDate =
      _start !== undefined ? DateTime.fromJSDate(_start) : undefined;
    const endDate = _end !== undefined ? DateTime.fromJSDate(_end) : undefined;

    s = {
      ...s,
      startDate:
        startDate !== undefined && startDate.isValid
          ? startDate.toMillis()
          : undefined,
      endDate:
        endDate !== undefined && endDate.isValid
          ? endDate.toMillis()
          : undefined,
    };

    const stringified = qs.stringify(s);

    return `/print/locations?${stringified}`;
  }

  render() {
    const { items, loading } = this.state;
    return (
      <div className='locations-planed customer container-inner container-inner-list'>
        <div className='page-header row justify-content-between'>
          <div className='col col-12 col-md-6'>
            <h1 className='page-title'>
              geplante Standorte {loading ? '' : `(${items.length})`}
            </h1>
            <div className='page-subtitle'>{this.renderFilter()}</div>
          </div>
          <div className='col col-12 col-md-6 page-header-actions justify-content-md-end pt-md-0'>
            <div className='col col-1 page-header-actions justify-content-md-end pt-md-0 foresighted-months'>
              {!loading && (
                <NavLink target='blank' to={this.getPrintUrl()}>
                  <PrintIcon />
                  Drucken
                </NavLink>
              )}
            </div>
            <div className='col col-1 page-header-actions justify-content-md-end pt-md-0 foresighted-months'>
              {!loading && (
                <Button
                  onClick={() => this.downloadExport()}
                  loading={this.state.download}
                  type='link'
                >
                  <DownloadIcon />
                  Export
                </Button>
              )}
            </div>
          </div>
        </div>
        <div className='page-content'>
          <CardList loading={loading}>
            {map(items, (item, index) => {
              return (
                <CardListItem
                  paddingLeft={false}
                  key={index}
                  title={`${item.name} [${item.tag}]`}
                  subtitle={uniq(
                    item.jobs.map((job) =>
                      DateTime.fromISO(job.planedAt)
                        .setLocale('de')
                        .toLocaleString({
                          month: '2-digit',
                          day: '2-digit',
                          weekday: 'short',
                          hour: '2-digit',
                          minute: '2-digit',
                        })
                    )
                  ).join(', ')}
                  link={`/administration/locations/${item._id}?p=1`}
                  action1={
                    <StatusAction state={Status.neutral} icon={<TourIcon />}>
                      <span>
                        Tour{' '}
                        {uniq(item.jobs.map((job) => job.tour.tag)).join(', ')}
                      </span>
                    </StatusAction>
                  }
                  action3={
                    <StatusAction state={Status.neutral} icon={<TechIcon />}>
                      <span>
                        {uniq(item.jobs.map((job) => job.technician.name)).join(
                          ', '
                        )}
                      </span>
                    </StatusAction>
                  }
                  action4={
                    <StatusAction
                      state={Status.neutral}
                      icon={<CustomerIcon />}
                      onClick={() => {
                        // open link in new tab
                        window.open(
                          `/administration/customers/${item.customer._id}?p=1`,
                          '_blank'
                        );
                      }}
                    >
                      <span>{item.customer.name}</span>
                    </StatusAction>
                  }
                >
                  <Row>
                    <Col xs={20} xl={10}>
                      <p>
                        <MapIcon />
                        {formatAddress(item)}
                        <CopyIcon
                          onClick={() => {
                            navigator.clipboard.writeText(formatAddress(item));
                            notification.open({
                              message: 'Zwischenablage',
                              description:
                                'Die Anschrift wurde in die Zwischenablage kopiert.',
                              icon: <CopyIcon style={{ color: '#009842' }} />,
                            });
                          }}
                        />
                      </p>
                      {item.phone && item.phone.length > 1 && (
                        <p>
                          <PhoneIcon />
                          {item.phone}
                          <CopyIcon
                            onClick={() => {
                              navigator.clipboard.writeText(item.phone);
                              notification.open({
                                message: 'Zwischenablage',
                                description:
                                  'Die Anschrift wurde in die Zwischenablage kopiert.',
                                icon: <CopyIcon style={{ color: '#009842' }} />,
                              });
                            }}
                          />
                        </p>
                      )}
                      {item.fax && item.fax.length > 1 && (
                        <p>
                          <FaxIcon />
                          {item.fax}{' '}
                          <CopyIcon
                            onClick={() => {
                              navigator.clipboard.writeText(item.fax);
                              notification.open({
                                message: 'Zwischenablage',
                                description:
                                  'Die Anschrift wurde in die Zwischenablage kopiert.',
                                icon: <CopyIcon style={{ color: '#009842' }} />,
                              });
                            }}
                          />
                        </p>
                      )}
                      {item.email && item.email.length > 1 && (
                        <p>
                          <EmailIcon />
                          {item.email}
                          <CopyIcon
                            onClick={() => {
                              navigator.clipboard.writeText(item.email);
                              notification.open({
                                message: 'Zwischenablage',
                                description:
                                  'Die Anschrift wurde in die Zwischenablage kopiert.',
                                icon: <CopyIcon style={{ color: '#009842' }} />,
                              });
                            }}
                          />
                        </p>
                      )}
                    </Col>
                    <Col>
                      <p>
                        <CommentIcon />
                        {[get(item, 'comment'), 'Keine Techniker Info']
                          .filter((f) => f && f.length > 0)
                          .slice(0, 1)
                          .join(' | ')}
                      </p>
                      <p>
                        <NoteIcon />
                        {[get(item, 'note'), 'Keine Planer Info']
                          .filter((f) => f && f.length > 0)
                          .slice(0, 1)
                          .join(' | ')}
                      </p>
                    </Col>
                  </Row>
                </CardListItem>
              );
            })}
          </CardList>
        </div>
      </div>
    );
  }
}

export default withRouter(ToursLocationsList);
