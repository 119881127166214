import './Modal.scss';

import React, { useEffect, useState } from 'react';

import DirectInput from '../Form/DirectInput';
import Modal from 'react-modal';
import classNames from 'classnames';
import { withWidth } from '../helper/modalCustomStyles';
import { Schema } from './Dashboard3';

import {
  colorLabels as TotalChartColorLabels,
  defaultColors as TotalChartDefaultColors,
} from './BuildInCharts/total';
import {
  colorLabels as RentaChartColorLabels,
  defaultColors as RentaChartDefaultColors,
} from './BuildInCharts/renta';
import {
  colorLabels as HotelsChartColorLabels,
  defaultColors as HotelsChartDefaultColors,
} from './BuildInCharts/hotels';
import {
  colorLabels as DailyRateColorLabels,
  defaultColors as DailyRateColorDefault,
} from './BuildInCharts/daily-rate';
import {
  colorLabels as TourRateColorLabels,
  defaultColors as TourRateColorDefault,
} from './BuildInCharts/tour-rate';
import {
  colorLabels as PlaningChartColorLabels,
  defaultColors as PlaningChartColorDefault,
} from './BuildInCharts/planing';
import {
  colorLabels as TechsChartColorLabels,
  defaultColors as TechsChartColorDefault,
} from './BuildInCharts/techs';
import {
  colorLabels as SalesLocationChartColorLabels,
  defaultColors as SalesLocationChartColorDefault,
} from './BuildInCharts/sales-locations';
import {
  colorLabels as LocationsDueChartColorLabels,
  defaultColors as LocationsDueChartColorDefault,
} from './BuildInCharts/locationsDue';
import {
  colorLabels as TopRentaChartColorLabels,
  defaultColors as TopRentaChartColorDefault,
} from './BuildInCharts/topRenta';
import {
  colorLabels as BottomRentaChartColorLabels,
  defaultColors as BottomRentaChartColorDefault,
} from './BuildInCharts/bottomRenta';
import {
  colorLabels as ToursChartColorLabels,
  defaultColors as ToursChartColorDefault,
} from './BuildInCharts/tours';

const getColorLabels = (type?: string) => {
  switch (type) {
    case 'TechsChart':
      return TechsChartColorLabels;
    case 'PlaningChart':
      return PlaningChartColorLabels;
    case 'SalesLocationChart':
      return SalesLocationChartColorLabels;
    case 'LocationsDueChart':
      return LocationsDueChartColorLabels;
    case 'TopRentaChart':
      return TopRentaChartColorLabels;
    case 'BottomRentaChart':
      return BottomRentaChartColorLabels;
    case 'HotelsChart':
      return HotelsChartColorLabels;
    case 'DailyRateChart':
      return DailyRateColorLabels;
    case 'TourRateChart':
      return TourRateColorLabels;
    case 'ToursChart':
      return ToursChartColorLabels;
    case 'TotalChart':
      return TotalChartColorLabels;
    case 'RentaChart':
      return RentaChartColorLabels;
    default:
      return [];
  }
};

const getColorDefualts = (type?: string) => {
  switch (type) {
    case 'TechsChart':
      return TechsChartColorDefault;
    case 'PlaningChart':
      return PlaningChartColorDefault;
    case 'SalesLocationChart':
      return SalesLocationChartColorDefault;
    case 'LocationsDueChart':
      return LocationsDueChartColorDefault;
    case 'TopRentaChart':
      return TopRentaChartColorDefault;
    case 'BottomRentaChart':
      return BottomRentaChartColorDefault;
    case 'HotelsChart':
      return HotelsChartDefaultColors;
    case 'DailyRateChart':
      return DailyRateColorDefault;
    case 'TourRateChart':
      return TourRateColorDefault;
    case 'ToursChart':
      return ToursChartColorDefault;
    case 'TotalChart':
      return TotalChartDefaultColors;
    case 'RentaChart':
      return RentaChartDefaultColors;
    default:
      return [];
  }
};

interface EditChartModalProps {
  chart?: Schema;
  isOpen: boolean;
  close: () => void;
  onSubmit: (chart: Schema) => Promise<void>;
}

export default function EditChartModal(props: EditChartModalProps) {
  Modal.setAppElement('#root');

  const { isOpen, close, onSubmit } = props;

  const [c, setC] = useState<Schema | undefined>(props.chart);
  const [labels, setLabels] = useState<string[]>(
    c ? getColorLabels(c.type) : []
  );

  useEffect(() => {
    setC(props.chart);
    setLabels(getColorLabels(props.chart?.type));
  }, [props.chart]);

  const valid = () => {
    return c !== undefined;
  };

  const submitClasses = classNames('btn', 'btn-primary', {
    disabled: !valid(),
  });

  const defaultColors = getColorDefualts(c?.type);
  const usedColors =
    !c || !c.colors || c.colors.length !== defaultColors.length
      ? defaultColors
      : c.colors;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => close && close()}
      style={withWidth('50%')}
      contentLabel=''
    >
      <div className='modal edit-chart-modal' tabIndex={-1} role='dialog'>
        <div className='modal-dialog' role='document'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Chart bearbeiten</h5>
            </div>
            <div className='modal-body'>
              {labels.map((label, i) => (
                <DirectInput
                  key={i}
                  title={label}
                  value={usedColors[i]}
                  callback={(value) => {
                    if (!c) {
                      return;
                    }
                    usedColors.splice(i, 1, value);
                    setC({ ...c, colors: usedColors });
                  }}
                  type={'color'}
                  // type='text'
                />
              ))}
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-secondary'
                data-dismiss='modal'
                onClick={() => close && close()}
              >
                Schließen
              </button>
              <button
                type='button'
                className={submitClasses}
                onClick={async () => {
                  if (onSubmit && valid()) {
                    await onSubmit(c!);
                  }
                }}
              >
                Speichern
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
