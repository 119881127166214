import React, { useEffect, useState } from 'react';

import GeplantIstChart from '../Charts/SollIstChart';
import { Interval } from 'luxon';
import { Request } from '../../../api/Request';
import classNames from 'classnames';
import renderLoading from '../../SharesActions/renderLoading';
import RemoveButton from './RemoveButton';
import EditButton from './EditButton';
import { Dot, Dots } from './extra';

export const defaultSize = {
  w: 2,
  h: 3,
  minW: 2,
  maxW: 4,
  minH: 2,
  maxH: 6,
};

export const defaultColors = ['#41a441', '#234a23'];
export const colorLabels = ['ist', 'geplant'];

export default function TopTechnicianChart(props: {
  delete?: () => void;
  edit?: () => void;
  dateRange: Interval;
  colors: string[];
}) {
  const usedColors =
    !props.colors || props.colors.length !== defaultColors.length
      ? defaultColors
      : props.colors;
  const [data, setData] = useState<
    {
      name: string;
      geplant: number;
      ist: number;
    }[]
  >([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      Request.list(
        'dashboard-data/technicians',
        {
          start: props.dateRange.start.toISO(),
          end: props.dateRange.end.toISO(),
        },
        true
      )
        .then((res) => {
          // handle success
          setData(res);
        })
        .catch((error) => {
          // handle error
          console.log(error);
        })
        .then(() => setLoading(false));
    };
    fetchData();
  }, [props.dateRange.end, props.dateRange.start]);

  const headerClass = classNames({
    'box-header': true,
    row: true,
    'justify-content-between': true,
    'has-title': true,
  });

  const editClass = classNames({
    box: true,
    double: true,
    'box-editing': false,
  });

  return (
    <div className={editClass}>
      <div className={headerClass}>
        <div className='col col-8'>
          <div className='box-title'>Techniker Umsatz (Soll-Ist)</div>
          {props.edit && <EditButton onClick={() => props.edit!()} />}{' '}
          {props.delete && <RemoveButton onClick={() => props.delete!()} />}
        </div>
        <div className='col col-4'>
          <Dots>
            <div>
              <Dot color='rgb(250, 164, 58)' />
              Geplant
            </div>
            <div>
              <Dot color='rgb(15, 131, 171)' />
              Ist
            </div>
          </Dots>
        </div>
      </div>
      <div className='box-content'>
        {loading && renderLoading()}
        {!loading && (
          <GeplantIstChart
            colors={usedColors}
            unit='€'
            keys={['ist', 'geplant']}
            link='administration/technicians'
            data={data}
            dateRange={props.dateRange}
            onClickDatum={(datum) => {
              return {
                technicians: datum.technicians,
                tours: [],
              };
            }}
          />
        )}
      </div>
    </div>
  );
}
