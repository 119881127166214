import React, { Fragment, FunctionComponent } from 'react';
import { NavLink } from 'react-router-dom';
import './Tours.scss';

import { chain, get, groupBy, identity } from 'lodash';

import { DateTime } from 'luxon';
import { ReactComponent as CustomerIcon } from '../../assets/icons/customer.svg';
import { ReactComponent as LocationIcon } from '../../assets/icons/location.svg';
import { ReactComponent as StatusIcon } from '../../assets/icons/status.svg';
import { ReactComponent as TechnicianIcon } from '../../assets/icons/technician2.svg';
import { ReactComponent as ToursIcon } from '../../assets/icons/tours.svg';
import { ReactComponent as UserIcon } from '../../assets/icons/user.svg';
import { ReactComponent as WrenchIcon } from '../../assets/icons/wrench.svg';
import TourMeta from './ListItem/TourMeta';
import classNames from 'classnames';

interface ListTourItemProps {
  item: any;
  fullWidth?: boolean;
  onClick?: () => void;
}

function TourStatus(props) {
  return (
    <div
      style={{
        marginTop: '0px',
      }}
    >
      <StatusIcon className='small' />
      <p className='header'>Status</p>
      <ul>
        <li className='status-text'>{get(props, 'item.statusText')}</li>
      </ul>
    </div>
  );
}

// Tour
function TourName(props) {
  const d = get(props, 'item.startDate');
  return (
    <Fragment>
      {d ? (
        <div
          style={{
            marginTop: '18px',
          }}
        >
          <ToursIcon className='small' />
          <p className='header'>{`Tour ${get(props, 'item.tag')}`}</p>
          <ul>
            <li>{DateTime.fromISO(d).setLocale('de').toFormat("'KW' WW")}</li>
          </ul>
        </div>
      ) : (
        false
      )}
    </Fragment>
  );
}

// Planer
function TourPlaner(props) {
  const d =
    get(props, 'item.createdBy.name') ??
    get(props, 'item.changedBy.name') ??
    'Planer gelöscht';
  return (
    <div
      style={{
        marginTop: '18px',
      }}
    >
      <UserIcon className='small' />
      <p className='header'>Planer</p>
      <ul>
        <li>{d}</li>
      </ul>
    </div>
  );
}

// Techniker
function TourTechnician(props) {
  const name = get(props, 'technicians[0].name', 'Techniker gelöscht');
  return (
    <div
      style={{
        marginTop: '0px',
      }}
    >
      <TechnicianIcon className='small' />
      <p className='header'>Techniker</p>
      <ul>
        <li>{name}</li>
      </ul>
    </div>
  );
}

function capitalize(s) {
  if (typeof s !== 'string' || !s) return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
}

function CustomerChip({ customer, onClick }) {
  const { initials, name, _id } = customer;

  const style = {
    display: 'inline-block',
    borderRadius: '4px',
    padding: '4px 8px',
    fontSize: '10px',
    margin: '4px 4px 4px 0',
    backgroundColor: '#f1f1f1',
  };

  const pre = name.match(/\b\w{2}/g) || [];
  const shortedName =
    capitalize(pre.shift() || '') + capitalize(pre.pop() || '');

  return (
    <NavLink
      to={`/administration/customers/${_id}`}
      style={style}
      onClick={onClick}
    >
      {initials ?? shortedName ?? '...'}
    </NavLink>
  );
}

// Einsätze
function TourCustomers(props) {
  const customers = chain(props.jobs)
    .uniqBy((j) => j.location?.customer?._id)
    .map((j) => j?.location?.customer)
    .filter(identity)
    .value();

  return (
    <Fragment>
      <div
        style={{
          marginTop: '18px',
          minHeight: '89px',
        }}
      >
        <CustomerIcon className='small' />
        <p className='header'>Kunden</p>
        <div style={{ padding: '0 0 0 32px' }}>
          {customers.map((c) => (
            <CustomerChip key={c._id} customer={c} onClick={props.onClick} />
          ))}
        </div>
      </div>
    </Fragment>
  );
}

// Tag
function TourDays(props) {
  const d = groupBy(props.jobs, (j) => DateTime.fromISO(j.planedAt).weekday);
  const s: any = { float: 'left', width: '22px', color: '#a1a1a1' };
  const sa = { float: 'left', width: '22px', color: '#000' };
  return (
    <Fragment>
      <div
        style={
          {
            // marginTop: '18px',
          }
        }
      >
        <WrenchIcon className='small' />
        <p className='header'>Einsatztage</p>
        <ul>
          <li style={d[1] ? sa : s}>Mo</li>
          <li style={d[2] ? sa : s}>Di</li>
          <li style={d[3] ? sa : s}>Mi</li>
          <li style={d[4] ? sa : s}>Do</li>
          <li style={d[5] ? sa : s}>Fr</li>
          <li style={d[6] ? sa : s}>Sa</li>
          <li style={d[0] ? sa : s}>So</li>
          <li style={{ width: '100px' }}>| {Object.keys(d).length} Tage</li>
        </ul>
      </div>
    </Fragment>
  );
}

// Locations
function TourLocations(props) {
  const locations = chain(props.jobs)
    .map((j) => (j.location ? j.location._id : undefined))
    .filter(identity)
    .uniq()
    .value();

  return (
    <Fragment>
      <div
        style={{
          marginTop: '18px',
        }}
      >
        <LocationIcon className='small' />
        <p className='header'>Standorte</p>
        <ul>
          <li>{locations.length} Standorte</li>
        </ul>
      </div>
    </Fragment>
  );
}

const ListTourItem: FunctionComponent<ListTourItemProps> = ({
  item,
  fullWidth,
  onClick,
}) => {
  const statusClass = item.status && item.status.split(':')[2];
  const jobs = item.jobs.filter((job) => !job.isHotel);
  const technicians = [item.technician];

  return (
    <div
      key={item._id}
      className={classNames('col col-12', {
        'col-xl-6': !fullWidth,
      })}
    >
      <NavLink
        to={`./tours/${item._id}${window.location.search}`}
        onClick={onClick}
      >
        <div className={`tour status-${statusClass}`}>
          <div className='row'>
            <div className='col col-12 col-sm-3'>
              <TourTechnician technicians={technicians} />
              <TourName item={item} />
              <TourPlaner item={item} />
            </div>
            <div className='col col-12 col-sm-4'>
              <TourStatus item={item} />
              <TourMeta item={item} />
            </div>
            <div className='col col-12 col-sm-5'>
              <TourDays jobs={jobs} />
              <TourLocations jobs={jobs} />
              <TourCustomers jobs={jobs} onClick={onClick} />
            </div>
          </div>
        </div>
      </NavLink>
    </div>
  );
};

export default ListTourItem;
