import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

interface DueWithin48MonthsProps {
  count?: number;
}

const W = styled.div`
  display: inline-block;
  margin: 0px 1rem;
  background-color: transparent;
  border: 1px solid rgb(229, 231, 235);
  border-radius: 4px;
  cursor: pointer;
  width: 14rem;
  position: relative;
  p {
    border: none;
    background-color: transparent;
    width: 100%;
    text-align: center;
    padding: 0.5rem;
    display: flex;
    color: rgb(0, 0, 0);
    line-height: 16px;
  }
`;

const DueWithin48Months: FunctionComponent<DueWithin48MonthsProps> = ({
  count,
}) => {
  return (
    <W>
      {count ? (
        <p>{count} StOe binnen 48 Mon.</p>
      ) : (
        <p>Vorrausblick wird geladen...</p>
      )}
    </W>
  );
};

export default DueWithin48Months;
