import React, { FunctionComponent } from 'react';
import showModalInput from '../../ModalInput';
import { Job } from '../../../context/Route';
import { ReactComponent as CommentIcon } from '../../../assets/icons/comment.svg';
import styled from 'styled-components';

interface ItemCommentProps {
  job: Job;
  onSave(value: string): void;
}

const Empty = styled.span`
  color: #ccc;
`;

const Wrapper = styled.p`
  cursor: pointer;
  user-select: none;
`;

const ItemComment: FunctionComponent<ItemCommentProps> = ({ job, onSave }) => {
  const handleClick = async () => {
    const newComment = await showModalInput(job.comment, 'Techniker Info');
    if (newComment !== undefined) {
      onSave(newComment);
    }
  };

  return (
    <Wrapper onClick={() => handleClick()}>
      <CommentIcon className='comment' />
      {job.comment && job.comment.length > 0 ? (
        job.comment
      ) : (
        <Empty>Keine Techniker Info</Empty>
      )}
    </Wrapper>
  );
};

export default ItemComment;
