import {
  ConfirmDialogProps,
  confirmable,
  createConfirmation,
} from 'react-confirm';

import { Button, Modal } from 'antd';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';

const Wrapper = styled.ul`
  list-style: none;
  padding: 24px 0 0 0;
`;

const Item = styled.li<{ danger?: boolean }>`
  // display: flex;
  // justify-content: space-between;
  margin-bottom: 2rem;
  color: ${(props) => (props.danger ? 'red' : 'black')};
`;

const ShowPlanedAtModal: FunctionComponent<
  ConfirmDialogProps<
    {
      value: Date;
      allowed?: {
        start: Date;
        end: Date;
      };
    },
    Date | false
  >
> = ({ allowed, proceed, value, show }) => {
  const [date, setDate] = React.useState<Date | null>(value);

  return (
    <Modal
      width={322}
      //   title={'Kundenbenachrichtigungen'}
      open={show}
      onCancel={() => proceed(false)}
      footer={[
        <Button
          key='submit'
          type='primary'
          onClick={() => {
            proceed(date ? date : false);
          }}
        >
          Speichern
        </Button>,
        <Button
          key='back'
          onClick={() => {
            proceed(false);
          }}
        >
          Schließen
        </Button>,
      ]}
    >
      <Wrapper>
        <DatePicker
          inline
          showWeekNumbers
          showTimeInput
          locale='de'
          todayButton='Heute'
          selected={date}
          onChange={(d) => setDate(d)}
          minDate={allowed ? allowed.start : undefined}
          maxDate={allowed ? allowed.end : undefined}
        />
      </Wrapper>
    </Modal>
  );
};

const showDateModal = (
  value: Date,
  allowed?: {
    start: Date;
    end: Date;
  }
) => {
  return createConfirmation(confirmable(ShowPlanedAtModal))({ value, allowed });
};

export default showDateModal;
