import './App.scss';
import './Page.scss';

import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import React, { Component, Suspense } from 'react';

import Account from '../Account/Account';
import AltLayout from '../Layouts/alternate.layout';
import AppRoute from '../Routes/AppRoute';
// Context
import { AuthProvider } from '../../context/AuthContext';
import { ClipLoader } from 'react-spinners';
import { ConfigProvider } from 'antd';
import CustomerEdit from '../Customer/Edit';
import CustomerGroupEdit from '../CustomerGroup/Edit';
import CustomerGroupList from '../CustomerGroup/List';
import CustomerGroupNew from '../CustomerGroup/New';
import CustomerList from '../Customer/List';
import CustomerNew from '../Customer/New';
import Dashboard from '../Dashboard/Dashboard3';
import Export from '../Export/Export';
import { FormProvider } from '../../context/FormContext';
import Import from '../Import/Import';
import LocationEdit from '../Location/Edit';
import LocationHistory from '../Location/History';
import LocationList from '../Location/List';
import LocationNew from '../Location/New';
// Pages
// import Login from '../Auth/Login';
import MainLayout from '../Layouts/main.layout';
import OfftimeOverview from '../Offtime/Overview';
import Page404 from '../Errors/404';
import PartnerEdit from '../Partner/Edit';
import PartnerList from '../Partner/List';
import PartnerNew from '../Partner/New';
import PlanningPrintLocations from '../Planing/PrintLocations';
import PlanningWrapper from '../Planing/PlanningWrapper';
import PlanningRelease from '../Planing/Release';
import PrintLayout from '../Layouts/print.layout';
import PrintLocationsList from '../Tours/Locations/PrintLocationsList';
import ProtectedAppRoute from '../Routes/ProtectedRoute';
import RelSettings from '../Rel/Settings/RelSettings';
import SettingsList from '../Settings/List';
import TechnicianEdit from '../Technician/Edit';
import TechnicianList from '../Technician/List';
import TechnicianNew from '../Technician/New';
import TourHistory from '../Tours/History';
import TourList from '../Tours/List';
import TourResponseView from '../Tours/RELRes';
import TourView from '../Tours/View';
import ToursHotelsList from '../Tours/Hotels/ToursHotelsList';
import ToursLocationsList from '../Tours/Locations/ToursLocationsList';
import UserEdit from '../User/Edit';
import UserList from '../User/List';
import UserNew from '../User/New';
import deDe from 'antd/es/locale/de_DE';
import AnalysisLocations from '../analysis/locations';

import EMailLayouts from '../EMailLayout/List';
import EMailLayout from '../EMailLayout/Edit';

import { MountPoint } from '../Dashboard/ShowToursModal';

function RedirectToPlaningLocations() {
  return <Redirect to='/planning/locations' />;
}

const routes = [
  { exact: true, path: '/', layout: MainLayout, comp: Dashboard },
  {
    exact: true,
    path: '/administration/users',
    layout: MainLayout,
    comp: UserList,
  },
  {
    exact: true,
    path: '/administration/users/new',
    layout: MainLayout,
    comp: UserNew,
  },
  {
    exact: true,
    path: '/administration/users/:id',
    layout: MainLayout,
    comp: UserEdit,
  },
  {
    exact: true,
    path: '/administration/partners',
    layout: MainLayout,
    comp: PartnerList,
  },
  {
    exact: true,
    path: '/administration/partners/new',
    layout: MainLayout,
    comp: PartnerNew,
  },
  {
    exact: true,
    path: '/administration/partners/:id',
    layout: MainLayout,
    comp: PartnerEdit,
  },
  {
    exact: true,
    path: '/administration/customers',
    layout: MainLayout,
    comp: CustomerList,
  },
  {
    exact: true,
    path: '/administration/customers/new',
    layout: MainLayout,
    comp: CustomerNew,
  },
  {
    exact: true,
    path: '/administration/customers/:id',
    layout: MainLayout,
    comp: CustomerEdit,
  },
  {
    exact: true,
    path: '/administration/customer-groups',
    layout: MainLayout,
    comp: CustomerGroupList,
  },
  {
    exact: true,
    path: '/administration/customer-groups/new',
    layout: MainLayout,
    comp: CustomerGroupNew,
  },
  {
    exact: true,
    path: '/administration/customer-groups/:id',
    layout: MainLayout,
    comp: CustomerGroupEdit,
  },
  {
    exact: true,
    path: '/administration/locations',
    layout: MainLayout,
    comp: LocationList,
  },
  {
    exact: true,
    path: '/administration/locations/new',
    layout: MainLayout,
    comp: LocationNew,
  },
  {
    exact: true,
    path: '/administration/locations/:id',
    layout: MainLayout,
    comp: LocationEdit,
  },
  {
    exact: true,
    path: '/administration/locations/:id/history',
    layout: MainLayout,
    comp: LocationHistory,
  },
  {
    exact: true,
    path: '/administration/technicians',
    layout: MainLayout,
    comp: TechnicianList,
  },
  {
    exact: true,
    path: '/administration/technicians/new',
    layout: MainLayout,
    comp: TechnicianNew,
  },
  {
    exact: true,
    path: '/administration/technicians/:id',
    layout: MainLayout,
    comp: TechnicianEdit,
  },
  {
    exact: true,
    path: '/administration/offtime',
    layout: MainLayout,
    comp: OfftimeOverview,
  },
  {
    exact: true,
    path: '/planning',
    layout: MainLayout,
    comp: RedirectToPlaningLocations,
  },
  {
    exact: true,
    path: '/planning/release',
    layout: MainLayout,
    comp: PlanningRelease,
  },
  {
    exact: false,
    path: '/planning/:step',
    layout: MainLayout,
    comp: PlanningWrapper,
  },
  {
    exact: false,
    path: '/print/planing/locations',
    layout: PrintLayout,
    comp: PlanningPrintLocations,
  },
  {
    exact: false,
    path: '/analysis/locations',
    layout: MainLayout,
    comp: AnalysisLocations,
  },
  { exact: true, path: '/tours', layout: MainLayout, comp: TourList },
  { exact: true, path: '/tours/:id', layout: MainLayout, comp: TourView },
  {
    exact: true,
    path: '/tours/:id/history',
    layout: MainLayout,
    comp: TourHistory,
  },
  {
    exact: true,
    path: '/tours/:id/response',
    layout: MainLayout,
    comp: TourResponseView,
  },
  {
    exact: true,
    path: '/locations',
    layout: MainLayout,
    comp: ToursLocationsList,
  },
  {
    exact: true,
    path: '/hotels',
    layout: MainLayout,
    comp: ToursHotelsList,
  },
  {
    exact: false,
    path: '/print/locations',
    layout: PrintLayout,
    comp: PrintLocationsList,
  },
  {
    exact: true,
    path: '/administration/settings',
    layout: MainLayout,
    comp: SettingsList,
  },
  {
    path: '/administration/import',
    layout: MainLayout,
    comp: Import,
  },
  // E-Mail Layouts
  {
    path: '/administration/email-templates/:id',
    layout: MainLayout,
    comp: EMailLayout,
  },
  {
    path: '/administration/email-templates',
    layout: MainLayout,
    comp: EMailLayouts,
  },
  // Export
  {
    path: '/export',
    layout: MainLayout,
    comp: Export,
  },
  {
    path: '/rel',
    layout: MainLayout,
    comp: RelSettings,
  },
];

class App extends Component {
  render() {
    return (
      // <ErrorBoundary>
      <BrowserRouter>
        <AuthProvider>
          <FormProvider>
            <ConfigProvider locale={deDe}>
              <div className='App'>
                <Suspense
                  fallback={
                    <div className='app-loader'>
                      <ClipLoader size={80} color={'#009842'} loading={true} />
                    </div>
                  }
                >
                  <MountPoint />
                  <Switch>
                    {routes.map((r) => (
                      <ProtectedAppRoute
                        key={r.path}
                        exact={r.exact}
                        path={r.path}
                        layout={r.layout}
                        component={r.comp}
                      />
                    ))}
                    {/* Auth
                      <AppRoute
                        exact
                        path='/auth'
                        layout={AltLayout}
                        component={Login}
                      /> */}
                    {/* Account */}
                    <AppRoute
                      path='/account'
                      layout={AltLayout}
                      component={Account}
                    />
                    {/* 404 */}
                    <Route component={Page404} />
                  </Switch>
                </Suspense>
              </div>
            </ConfigProvider>
          </FormProvider>
        </AuthProvider>
      </BrowserRouter>
      // </ErrorBoundary>
    );
  }
}

export default App;
