import download from 'downloadjs';
import React, { FunctionComponent } from 'react';
import { Request } from '../../api/Request';
import { Location } from '../../context/Route';
import DownloadInput from '../Form/DownloadInput';
import UploadInput from '../Form/UploadInput';

interface FilesProps {
  location?: Location;
  onChanged?: () => void;
}

const Files: FunctionComponent<FilesProps> = ({ onChanged, location }) => {
  if (!location) {
    return <div>NO_FILES</div>;
  }

  const uploadFile = async (file: File, cb: (result: boolean) => void) => {
    Request.upload('locations', location._id, file, {}, 'files')
      .then(() => {
        cb(true);
        onChanged && onChanged();
      })
      .catch((e) => {
        console.error(e);
        cb(false);
      });
  };

  const deleteFile = async (file: any) => {
    debugger;
    Request.delete('locations', location._id, `files/${file._id}`).then(() => {
      debugger;
      onChanged && onChanged();
    });
  };

  const downloadFile = async (file: any) => {
    const { base64, originalname, mimetype } = await Request.list(
      `locations/${location._id}/files/${file._id}`,
      {}
    );
    download(base64, originalname, mimetype);
  };

  return (
    <div>
      {location.files?.map((file) => (
        <DownloadInput
          key={file.id}
          value={file}
          onDelete={() => deleteFile(file)}
          onDownload={() => downloadFile(file)}
        />
      ))}
      <div>
        <UploadInput
          type='file'
          title='Hochladen'
          callback={(value: any, cb: any) => uploadFile(value, cb)}
        />
      </div>
    </div>
  );
};

export default Files;
