import styled from 'styled-components';

export const Dots = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-right: 10px;
  float: right;
`;

export const Dot = styled.div<{
  color: string;
}>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 4px;
  background-color: ${(props) => props.color};
  margin-left: 8px;
`;
