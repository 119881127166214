import './styles.scss';

import App from './components/App/App';
import React from 'react';
import { createRoot } from 'react-dom/client';
import packageInfo from '../package.json';

import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';

/**
 * Begin Datadog
 */
datadogRum.init({
  applicationId: '73701334-72fe-44b6-a31e-528fe999281d',
  clientToken: 'pub2985b1d1af231573462c0b3da67149b2',
  site: 'datadoghq.eu',
  service: 'safeplan',
  env: 'production',
  version: packageInfo.version,
  sessionSampleRate: 50,
  sessionReplaySampleRate: 50,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'allow',
});

datadogLogs.init({
  clientToken: 'pub2985b1d1af231573462c0b3da67149b2',
  site: 'datadoghq.eu',
  service: 'safeplan',
  env: 'production',
  forwardErrorsToLogs: true,
  sampleRate: 100,
});

datadogRum.startSessionReplayRecording();
/**
 * End Datadog
 */

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<App />);
