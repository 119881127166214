import {
  ConfirmDialogProps,
  confirmable,
  createConfirmationCreater,
  createMountPoint,
  createReactTreeMounter,
} from 'react-confirm';
import { Request } from '../../api/Request';

import { Button, Modal } from 'antd';
import React, { FunctionComponent, useEffect } from 'react';
import styled from 'styled-components';

import { Interval } from 'luxon';
import ListTourItem from '../Tours/ListTourItem';

interface V {
  technicians: string[];
  tours: string[];
  dateRange: Interval;
  title: string;
}

type EnterHotalProps = {
  value?: V;
};

const Wrapper = styled.ul`
  list-style: none;
  padding: 0;
  overflow-y: auto;
  overlay: hidden;
  background: rgb(242, 242, 242);
  max-height: 60vh;
  .ant-modal-body {
    background: rgb(242, 242, 242);
  }
`;

const ShowToursModal: FunctionComponent<
  ConfirmDialogProps<EnterHotalProps, false>
> = ({ proceed, value, show }) => {
  const [loading, setLoading] = React.useState(true);
  const [items, setItems] = React.useState<any[]>([]);

  useEffect(() => {
    const loadData = async () => {
      if (!value) return;
      if (!value.dateRange) return;
      setLoading(true);

      const params = {
        limit: 5000,
        page: 0,
        start: value?.dateRange.start.toISO(),
        end: value?.dateRange.end.toISO(),
        technicians: value.technicians,
        tours: value.tours,
      };

      console.log('value', value);
      console.log('params', params);

      const { items } = await Request.list('tours', params);
      setItems(items);
      setLoading(false);
    };
    loadData();
  }, []);

  return (
    <>
      <style>{` .ant-modal-body { background: rgb(242, 242, 242); } `}</style>
      <Modal
        width={1020}
        title={value?.title}
        open={show}
        onCancel={() => proceed(false)}
        footer={[
          <Button
            key='back'
            onClick={() => {
              proceed(false);
            }}
          >
            Schließen
          </Button>,
        ]}
      >
        <Wrapper className='tours container-inner'>
          {loading ? 'Loading...' : null}
          {!loading
            ? items.map((item, index) => (
                <ListTourItem
                  fullWidth
                  key={index}
                  item={item}
                  onClick={() => proceed(false)}
                />
              ))
            : null}
        </Wrapper>
      </Modal>
    </>
  );
};

const mounter = createReactTreeMounter();
export const createConfirmation = createConfirmationCreater(mounter);
export const MountPoint = createMountPoint(mounter);

export function showTours(value: V | undefined) {
  return createConfirmation(confirmable(ShowToursModal))({
    value,
  });
}
