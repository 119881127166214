import React, { FunctionComponent, useMemo, useState } from 'react';
import { ReactComponent as FixedIcon } from '../../../assets/icons/date.svg';
import { Job } from '../../../context/Route';
import StatusAction, {
  Status,
} from '../../common/CardList/Actions/StatusAction';
import { setFixed } from './utils/set-fixed';

interface FixedProps {
  job: Job;
  readonly?: boolean;
  onChanged?: () => void;
}

const Fixed: FunctionComponent<FixedProps> = ({ job, onChanged }) => {
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    setLoading(true);
    await setFixed(job, !job.fixed);
    onChanged && onChanged();
    setLoading(false);
  };

  const state = useMemo(() => {
    if (job.rejected) return Status.warning;
    if (job.fixed) return Status.success;
    return Status.neutral;
  }, [job.fixed, job.rejected]);

  return (
    <StatusAction
      loading={loading}
      noBackground={!job.fixed}
      state={state}
      icon={<FixedIcon />}
      onClick={handleClick}
    >
      {state === Status.neutral && <span>Termin fixieren</span>}
      {state === Status.success && <span>Termin fix</span>}
      {state === Status.warning && <span>Termin abgelehnt</span>}
    </StatusAction>
  );
};

export default Fixed;
