import './Planning.scss';

import { NavLink, Redirect } from 'react-router-dom';
import React, { Component } from 'react';

import { ReactComponent as BackIcon } from '../../assets/icons/left.svg';
import { PlanningConsumer } from '../../context/PlanningContext';
import Popconfirm from 'antd/es/popconfirm';
import { StepHeader } from './PlanningWrapper';
import classNames from 'classnames';

interface PlanningStepperHeaderProps {
  currentStep: number;
  steps: StepHeader[];
}

interface PlanningStepperHeaderState {
  currentStep: number;
  steps: StepHeader[];
  redirect: boolean;
}

class PlanningStepperHeader extends Component<
  PlanningStepperHeaderProps,
  PlanningStepperHeaderState
> {
  state: PlanningStepperHeaderState = {
    currentStep: 0,
    steps: [],
    redirect: false,
  };

  constructor(props: PlanningStepperHeaderProps) {
    super(props);
    if (props.currentStep) this.state.currentStep = props.currentStep;
    if (props.steps) this.state.steps = props.steps;
  }

  componentDidUpdate(prevProps: PlanningStepperHeaderProps) {
    let p = {};
    if (this.props.currentStep !== prevProps.currentStep)
      p = { ...p, currentStep: this.props.currentStep };
    if (this.props.steps !== prevProps.steps)
      p = { ...p, steps: this.props.steps };
    if (Object.keys(p).length > 0) {
      this.setState(p);
    }
  }

  getNextTarget() {
    const { currentStep } = this.state;
    switch (currentStep) {
      case 0:
        return 'technicians';
      case 1:
        return 'route';
      case 2:
        return 'complete';
      default:
        return undefined;
    }
  }

  getPreviousTarget() {
    const { currentStep } = this.state;
    switch (currentStep) {
      case 1:
        return 'locations';
      case 2:
        return 'technicians';
      case 3:
        return 'route';
      default:
        return undefined;
    }
  }

  render() {
    const { currentStep, steps, redirect } = this.state;
    const stepObj = steps[currentStep];
    const target = this.getNextTarget();
    const targetPrevious = this.getPreviousTarget();

    if (redirect) {
      return <Redirect to='/planning' />;
    }

    return (
      <PlanningConsumer>
        {({
          locations,
          technicians,
          route,
          planningWeek,
          reset,
          exportState,
          wasEdit,
          info,
          loadingInfo,
        }) => {
          const stepInfo = stepObj.info(
            locations || [],
            technicians || [],
            route,
            planningWeek,
            info,
            loadingInfo || false
          );

          const hasAlert = stepObj.hasAlert(
            locations || [],
            technicians || [],
            route,
            planningWeek,
            info,
            loadingInfo || false
          );

          const canNext = stepObj.canNext(
            locations || [],
            technicians || [],
            route,
            planningWeek,
            info,
            loadingInfo || false
          );

          const subText = stepObj.subtext(
            locations || [],
            technicians || [],
            route,
            planningWeek,
            info,
            loadingInfo || false
          );

          const tile = `Auftrag ${wasEdit ? 'bearbeiten' : 'planen'}`;

          return (
            <div className='planning-stepper-header'>
              <div className='steps-pre'>
                {targetPrevious && (
                  <NavLink to={`/planning/${targetPrevious}`}>
                    <BackIcon />
                  </NavLink>
                )}
                {/* <h2>{tile}</h2> */}
              </div>
              <div className='steps'>
                <div className='steps-inner'>
                  {steps.map((_step, index) => (
                    <div
                      key={index}
                      className={classNames('step', `step-${index}`, {
                        active: index === currentStep,
                      })}
                    >
                      <div className='step-header'>
                        <span className='circle'>{index + 1}</span>
                        <span>{_step.text}</span>
                      </div>
                      <div className='step-subtext'>
                        {index === currentStep && subText}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className='steps-info'>{stepInfo}</div>
              <div className='steps-post'>
                <NavLink
                  className={classNames('button', {
                    alert: hasAlert,
                    disabled: !canNext,
                  })}
                  to={`/planning/${target}`}
                >
                  Weiter
                </NavLink>
                {
                  <div className='row'>
                    <div className='col'>
                      <div
                        onClick={async () => {
                          exportState && (await exportState());
                        }}
                      >
                        <p className='small reset-all'>Export für Helpdesk</p>
                      </div>
                    </div>
                    <div className='col'>
                      <Popconfirm
                        placement='bottomRight'
                        title='Sind Sie sich sicher?'
                        onConfirm={async () => {
                          reset && (await reset());
                          this.setState({ redirect: true });
                        }}
                        okText='Ja'
                        cancelText='Nein'
                      >
                        <p className='small reset-all'>Zurücksetzen</p>
                      </Popconfirm>
                    </div>
                  </div>
                }
              </div>
            </div>
          );
        }}
      </PlanningConsumer>
    );
  }
}

export default PlanningStepperHeader;
