import { Chart, AxisOptions } from 'react-charts';

import React from 'react';
import numeral from 'numeral';
import { showTours } from '../ShowToursModal';

numeral.locale('de');

export default function PercentageChart(props) {
  if (!props.data || props.data.length === 0) {
    return null;
  }

  // ist and geplant will be stacked
  // both will be positive or negative
  // there are four possible cases
  // 1) both values are positive and ist > geplant
  // 2) both values are positive and geplant > ist
  // 3) both values are negative and ist > geplant
  // 4) both values are negative and geplant > ist

  const getValueForGeplant = (ist: number, geplant: number) => {
    if (ist === null || ist === 0) {
      return geplant;
    }

    // case 1
    if (geplant > 0 && ist > 0 && ist > geplant) {
      return geplant;
    }
    // case 2
    if (geplant > 0 && ist > 0 && geplant > ist) {
      return geplant - ist;
    }
    // case 3 & 4
    if (geplant < 0 || ist < 0) {
      return geplant;
    }
  };

  const getValueForIst = (ist: number, geplant: number) => {
    if (geplant === null || geplant === 0) {
      return ist;
    }

    // case 1
    if (geplant > 0 && ist > 0 && ist > geplant) {
      return ist - geplant;
    }
    // case 2
    if (geplant > 0 && ist > 0 && geplant > ist) {
      return ist;
    }
    // case 3 & 4
    if (geplant < 0 || ist < 0) {
      return ist;
    }
  };

  const sortItems = (
    a: { ist: number; geplant: number },
    b: { ist: number; geplant: number }
  ) => {
    if ((!a && !b) || (!a.ist && !b.ist) || (a.ist === 0 && b.ist === 0)) {
      return a.geplant - b.geplant;
    }

    return a.ist - b.ist;
  };

  const data = React.useMemo(() => {
    return [
      {
        label: 'Prozent',
        data: props.data
          .map((d) => ({
            technicians: d.technicians,
            tours: d.tours,
            primary: d.name.toString(),
            secondary: d.geplant,
            geplant: d.geplant,
            ist: d.ist,
            prozent: d.prozent,
            _id: d._id,
          }))
          .sort((a, b) => {
            return a.prozent - b.prozent;
          }),
      },
    ];
  }, [props.data, props.keys]);

  // console.log(data);

  const primaryAxis = React.useMemo<
    AxisOptions<(typeof data)[number]['data'][number]>
  >(
    () => ({
      position: 'left',
      getValue: (datum) => datum.primary,
      innerBandPadding: 0.2,
      outerBandPadding: 0.1,
    }),
    []
  );

  const secondaryAxes = React.useMemo<
    AxisOptions<(typeof data)[number]['data'][number]>[]
  >(
    () => [
      {
        position: 'bottom',
        getValue: (datum) => datum.prozent,
        stacked: false,
      },
    ],
    []
  );

  const handleClick = (_id: string) => {
    console.log(_id);
    const win = window.open(`/${props.link}/${_id}`, '_blank');
    win?.focus();
  };

  return (
    <Chart
      options={{
        data,
        primaryAxis,
        secondaryAxes,
        onClickDatum: (datum) => {
          if (datum && datum.originalDatum) {
            const ids: {
              technicians: string[];
              tours: string[];
            } = props.onClickDatum(datum.originalDatum);

            showTours({
              ...ids,
              dateRange: props.dateRange,
              title: (datum.originalDatum as any).primary,
            });
          }
        },
        // tooltip: false,
      }}
    />
  );
}
