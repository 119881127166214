import './EditableInput.scss';

import React, { FunctionComponent } from 'react';

import { ReactComponent as DeleteIcon } from '../../assets/icons/trash.svg';
import { ReactComponent as DownloadIcon } from '../../assets/icons/download.svg';

interface DownloadInputProps {
  value: any;
  onDelete: () => void;
  onDownload: () => void;
}

const DownloadInput: FunctionComponent<DownloadInputProps> = ({
  value,
  onDownload,
  onDelete,
}) => {
  return (
    <div className='editable-input'>
      <label>Datei</label>
      <input disabled type='text' value={value.originalname}></input>
      <div className='actions'>
        <DeleteIcon className='edit' onClick={onDelete} />
        <DownloadIcon className='edit' onClick={onDownload} />
      </div>
    </div>
  );
};

export default DownloadInput;
