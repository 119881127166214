import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { ReactComponent as NoteIcon } from '../../../assets/icons/notes.svg';
import { Job } from '../../../context/Route';
import showModalInput from '../../ModalInput';

interface ItemNoteProps {
  job: Job;
  onSave(value: string): void;
}

const Empty = styled.span`
  color: #ccc;
`;

const Wrapper = styled.p`
  cursor: pointer;
  user-select: none;
`;

const ItemNote: FunctionComponent<ItemNoteProps> = ({ job, onSave }) => {
  const handleClick = async () => {
    const newNote = await showModalInput(job.note, 'Planer Info');

    if (newNote !== undefined) {
      onSave(newNote);
    }
  };

  return (
    <Wrapper onClick={() => handleClick()}>
      <NoteIcon className='note' />
      {job.note && job.note.length > 0 ? (
        job.note
      ) : (
        <Empty>Keine Planer Info</Empty>
      )}
    </Wrapper>
  );
};

export default ItemNote;
