import './Customer.scss';

import React, { Component } from 'react';
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom';
import Table, { SELECTION_TYPE } from '../Table/Table';

import { AuthConsumer } from '../../context/AuthContext';
import { EMAIL_REGEXP } from '../regex';
import EditableInput from '../Form/EditableInput';
import { FormConsumer } from '../../context/FormContext';
import InPageNavigation from '../InPageNavigation/InPageNavigation';
import RemoveButton from '../SharesActions/Remove';
import { Request } from '../../api/Request';
import { ResponseError } from 'superagent';
import SelectInput from '../Form/SelectInput';
import { hasPermissionFunc } from '../../context/hasPermissionFunc';
import { notification } from 'antd';
import prepareForDropdown from '../helper/prepareForDropdown';
import renderLoading from '../SharesActions/renderLoading';
import { set } from 'lodash';
import shareTypeToText from '../helper/shareTypeToText';
import Files from './Files';

type Customer = any;

interface MatchParams {
  id: string;
}

interface CustomerEditProps extends RouteComponentProps<MatchParams> {}

interface CustomerEditState {
  redirectList: boolean;
  error?: ResponseError;
  loading: boolean;
  item?: Customer;
  partners: any[];
  groups: any[];
  locations: any[];
  tasks: any[];
  editing: boolean;
}

class CustomerEdit extends Component<CustomerEditProps, CustomerEditState> {
  state: CustomerEditState = {
    redirectList: false,
    editing: false,
    loading: true,
    partners: [],
    groups: [],
    locations: [],
    tasks: [],
  };

  header = [
    { key: 'name', width: '2-5', sort: false, title: 'Standort' },
    { key: 'tag', width: '1-5', sort: false, title: 'Standortnummer' },
    { key: 'address.street', width: 2, sort: false, title: 'Straße' },
    { key: 'address.postalCode', width: '0-5', sort: false, title: 'PLZ' },
    { key: 'address.city', width: '1-5', sort: false, title: 'Ort' },
    { key: 'contact', width: '1', sort: false, title: 'Ansprechpartner' },
    { key: 'phone', width: '1', sort: false, title: 'Mail' },
    { key: 'email', width: '1', sort: false, title: 'E-Mail' },
  ];

  componentDidMount() {
    this.loadData();
  }

  setPromisifiedState(data: any) {
    return new Promise<void>((resolve) => this.setState(data, () => resolve()));
  }

  async remove() {
    try {
      await Request.delete('customers', this.state.item._id);
      this.setState({ redirectList: true });
    } catch (error: any) {
      if (error.status === 404) {
        this.setState({
          redirectList: true,
        });
      }
    }
  }

  async loadData() {
    const { id } = this.props.match.params;
    try {
      const [item, locations, partners, groups] = await Promise.all([
        Request.get('customers', id),
        Request.get('customers', id, 'locations', {
          sort: 'address.postalCode',
          limit: 25000,
          small: true,
        }).catch((e) => {
          console.error(e);
          return [];
        }),
        Request.list('partners', { limit: 25000 }).catch((e) => {
          console.error(e);
          return [];
        }),
        Request.list('customer-groups', { limit: 25000 }).catch((e) => {
          console.error(e);
          return [];
        }),
      ]);

      this.setState({
        loading: false,
        item,
        locations,
        partners: prepareForDropdown(partners.items),
        groups: prepareForDropdown(groups.items),
      });
    } catch (error: any) {
      if (error.status === 404) {
        this.setState({
          loading: false,
          redirectList: true,
        });
      } else {
        notification.error({
          message: 'Fehler beim Laden',
          description:
            'Der Kunde konnte aufgrund eines Fehlers nicht geladen werden.',
        });
        this.setState({
          loading: false,
          error,
        });
      }
    }
  }

  async save(key: string, value: any) {
    try {
      const data: any = {};
      data[key] = value;
      const item = await Request.put('customers', this.state.item._id, data);
      this.setState({ item });
    } catch (error: any) {
      // console.log(error);
      notification.error({
        message: 'Fehler beim Speichern',
        description: 'Die Änderung konnte nicht gespeichert werden.',
      });
      this.setState({
        loading: false,
        error,
        redirectList: error.status === 404,
      });
    }
  }

  async saveREL(file: File, cb: any) {
    if (!file) {
      Request.delete('customers', this.state.item._id, 'file/rel').then(() => {
        this.setState({
          loading: false,
          item: set(this.state.item, 'relFile', null),
        });
        cb(true);
      });
    } else {
      Request.upload('customers', this.state.item._id, file, 'file/rel').then(
        (result) => {
          this.setState({
            loading: false,
            item: set(this.state.item, 'relFile', result),
          });
          cb(true);
        }
      );
    }
  }

  getPartner(item: Customer) {
    if (!item || !item.partner) return null;
    if (typeof item.partner === 'string') return item.partner;
    return item.partner._id;
  }

  getGroup(item: Customer) {
    if (!item || !item.group) return null;
    if (typeof item.group === 'string') return item.group;
    return item.group._id;
  }

  render() {
    const { item, partners, locations, groups } = this.state;
    if (this.state.redirectList)
      return <Redirect to='/administration/customers' />;
    if (!item) return renderLoading();
    return (
      <AuthConsumer>
        {({ hasPermission }: { hasPermission: hasPermissionFunc }) => (
          <div className='customer container-inner'>
            <InPageNavigation to='/administration/customers' item={item} />
            <div className='page-header page-header-line row justify-content-between'>
              <div className='col-6 col'>
                <h1 className='page-title'>{item.name}</h1>
              </div>
              <div className='col-6 col'>
                <RemoveButton
                  hidden={!hasPermission(['customer:write'])}
                  itemTypeText='Kunde'
                  callback={() => this.remove()}
                />
              </div>
            </div>
            <FormConsumer>
              {({ isEditing }: { isEditing: boolean }) => (
                <div className={`page-content ${isEditing ? 'editing' : ''}`}>
                  <div className='row'>
                    <div className='col col-12 col-xs-6 col-md-6 col-lg-6 col-xl-6'>
                      <div className='row table-divider'>
                        <div className='col'>
                          <span>Stammdaten</span>
                        </div>
                      </div>
                      <div className='row table-row input-row'>
                        <div className='col'>
                          <SelectInput
                            title='Kundengruppe'
                            readOnly={
                              hasPermission(['customer:write']) !== true
                            }
                            value={this.getGroup(item)}
                            options={groups}
                            callback={(value: string) =>
                              this.save('group', value)
                            }
                          />
                        </div>
                      </div>
                      <div className='row table-row input-row'>
                        <div className='col'>
                          <EditableInput
                            title='Kundenummer'
                            readOnly={
                              hasPermission(['customer:write']) !== true
                            }
                            value={item.customernumber}
                            validate={(value: string) =>
                              value !== undefined &&
                              value !== null &&
                              value.length > 2
                            }
                            callback={(value: string) =>
                              this.save('customernumber', value)
                            }
                          />
                        </div>
                      </div>
                      <div className='row table-row input-row'>
                        <div className='col'>
                          <EditableInput
                            title='Kundenname'
                            readOnly={
                              hasPermission(['customer:write']) !== true
                            }
                            value={item.name}
                            validate={(value: string) =>
                              value !== undefined &&
                              value !== null &&
                              value.length > 2
                            }
                            callback={(value: string) =>
                              this.save('name', value)
                            }
                          />
                        </div>
                      </div>
                      <div className='row table-row input-row'>
                        <div className='col'>
                          <EditableInput
                            title='Kürzel'
                            readOnly={
                              hasPermission(['customer:write']) !== true
                            }
                            value={item.initialsOverwrite}
                            defaultValue={item.defautlInitials}
                            validate={(value: string) =>
                              !value ||
                              (value && value.length > 0 && value.length < 8)
                            }
                            callback={(value: string) =>
                              this.save('initialsOverwrite', value)
                            }
                          />
                        </div>
                      </div>
                      <div className='row table-row input-row'>
                        <div className='col'>
                          <SelectInput
                            title='Partner'
                            readOnly={
                              hasPermission(['customer:write']) !== true
                            }
                            value={this.getPartner(item)}
                            options={partners}
                            callback={(value: string) =>
                              this.save('partner', value)
                            }
                          />
                        </div>
                      </div>
                      <div className='row table-divider'>
                        <div className='col'>
                          <span>Rechnungsadresse</span>
                        </div>
                      </div>
                      <div className='row table-row input-row'>
                        <div className='col'>
                          <EditableInput
                            title='E-Mail'
                            readOnly={
                              hasPermission(['customer:write']) !== true
                            }
                            value={item.billingEmail}
                            type='email'
                            validate={(value: string) =>
                              value && EMAIL_REGEXP.exec(value) !== null
                            }
                            callback={(value: string) =>
                              this.save('billingEmail', value)
                            }
                          />
                        </div>
                      </div>
                      <div className='row table-row input-row'>
                        <div className='col'>
                          <EditableInput
                            title='Straße'
                            readOnly={
                              hasPermission(['customer:write']) !== true
                            }
                            value={
                              item.billingAddress
                                ? item.billingAddress.street
                                : null
                            }
                            callback={(value: string) =>
                              this.save('billingAddress', { street: value })
                            }
                          />
                        </div>
                      </div>
                      <div className='row table-row input-row'>
                        <div className='col'>
                          <EditableInput
                            title='Plz'
                            readOnly={
                              hasPermission(['customer:write']) !== true
                            }
                            value={
                              item.billingAddress
                                ? item.billingAddress.postalCode
                                : null
                            }
                            callback={(value: string) =>
                              this.save('billingAddress', { postalCode: value })
                            }
                          />
                        </div>
                      </div>
                      <div className='row table-row input-row'>
                        <div className='col'>
                          <EditableInput
                            title='Stadt'
                            readOnly={
                              hasPermission(['customer:write']) !== true
                            }
                            value={
                              item.billingAddress
                                ? item.billingAddress.city
                                : null
                            }
                            callback={(value: string) =>
                              this.save('billingAddress', { city: value })
                            }
                          />
                        </div>
                      </div>
                      <div className='row table-row input-row'>
                        <div className='col'>
                          <EditableInput
                            title='Land'
                            readOnly={
                              hasPermission(['customer:write']) !== true
                            }
                            value={
                              item.billingAddress
                                ? item.billingAddress.countryCode
                                : null
                            }
                            callback={(value: string) =>
                              this.save('billingAddress', {
                                countryCode: value,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className='col col-12 col-xs-6 col-md-6 col-lg-6 col-xl-6'>
                      <div className='row table-divider'>
                        <div className='col'>
                          <span>Benachrichtigungseinstellungen</span>
                        </div>
                      </div>
                      <div className='row table-row input-row'>
                        <div className='col'>
                          <EditableInput
                            title='Termin'
                            readOnly={true}
                            value='Pro Standort'
                            type='text'
                          />
                        </div>
                      </div>
                      <div className='row table-row input-row'>
                        <div className='col'>
                          <SelectInput
                            title='Design'
                            value={item.shareType}
                            options={
                              item.group && item.group.shareType
                                ? [
                                    {
                                      value: 'datetime',
                                      text: shareTypeToText('datetime'),
                                    },
                                    {
                                      value: 'date',
                                      text: shareTypeToText('date'),
                                    },
                                    {
                                      value: 'kw',
                                      text: shareTypeToText('kw'),
                                    },
                                    {
                                      value: '',
                                      text: `Von Kundegruppe (${shareTypeToText(
                                        item.group.shareType
                                      )})`,
                                    },
                                  ]
                                : [
                                    {
                                      value: 'datetime',
                                      text: shareTypeToText('datetime'),
                                    },
                                    {
                                      value: 'date',
                                      text: shareTypeToText('date'),
                                    },
                                    {
                                      value: 'kw',
                                      text: shareTypeToText('kw'),
                                    },
                                  ]
                            }
                            readOnly={
                              hasPermission(['customer:write']) !== true
                            }
                            callback={(value: string) =>
                              this.save('shareType', value)
                            }
                          />
                        </div>
                      </div>
                      <div className='row table-row input-row'>
                        <div className='col'>
                          <EditableInput
                            title='Berichte'
                            readOnly={
                              hasPermission(['customer:write']) !== true
                            }
                            value={item.reportEmail}
                            type='email'
                            validate={(value: string) =>
                              value && EMAIL_REGEXP.exec(value) !== null
                            }
                            callback={(value: string) =>
                              this.save('reportEmail', value)
                            }
                          />
                        </div>
                      </div>
                      <div className='row table-divider'>
                        <div className='col'>
                          <span>Kontakt</span>
                        </div>
                      </div>
                      <div className='row table-row input-row'>
                        <div className='col'>
                          <EditableInput
                            title='E-Mail'
                            readOnly={
                              hasPermission(['customer:write']) !== true
                            }
                            value={item.email}
                            type='email'
                            validate={(value: string) =>
                              value && EMAIL_REGEXP.exec(value) !== null
                            }
                            callback={(value: string) =>
                              this.save('email', value)
                            }
                          />
                        </div>
                      </div>
                      <div className='row table-row input-row'>
                        <div className='col'>
                          <EditableInput
                            title='Telefon'
                            readOnly={
                              hasPermission(['customer:write']) !== true
                            }
                            value={item.phone}
                            validate={(value: string) =>
                              value !== undefined &&
                              value !== null &&
                              value.length > 2
                            }
                            callback={(value: string) =>
                              this.save('phone', value)
                            }
                          />
                        </div>
                      </div>
                      <div className='row table-row input-row'>
                        <div className='col'>
                          <EditableInput
                            title='Fax'
                            readOnly={
                              hasPermission(['customer:write']) !== true
                            }
                            value={item.fax}
                            validate={(value: string) =>
                              value !== undefined &&
                              value !== null &&
                              value.length > 2
                            }
                            callback={(value: string) =>
                              this.save('fax', value)
                            }
                          />
                        </div>
                      </div>
                      <div className='row table-divider'>
                        <div className='col'>
                          <span>Sonstiges</span>
                        </div>
                      </div>
                      <div className='row table-row input-row'>
                        <div className='col'>
                          <EditableInput
                            title='Techniker Info'
                            readOnly={
                              hasPermission(['customer:write']) !== true
                            }
                            value={item.comment}
                            callback={(value: string) =>
                              this.save('comment', value)
                            }
                          />
                        </div>
                      </div>
                      <div className='row table-divider'>
                        <div className='col'>
                          <span>Dateien</span>
                        </div>
                      </div>
                      <div className='row table-row input-row'>
                        <div className='col-12'>
                          <Files
                            location={item}
                            onChanged={() => this.loadData()}
                          />
                        </div>
                      </div>
                      {/* <div className='row table-row input-row'>
                        <div className='col'>
                          <UploadInput
                            type='file'
                            accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                            title='REL'
                            placeholder='Standard REL'
                            readOnly={
                              hasPermission(['customer:write']) !== true
                            }
                            value={item.relFile}
                            callback={(value: any, cb: any) =>
                              this.saveREL(value, cb)
                            }
                          />
                        </div>
                      </div> */}
                    </div>
                  </div>
                  <div className='row row-divider-top'>
                    <div className='col col-12'>
                      <div className='row table-divider'>
                        <div className='col'>
                          <span>
                            Standorte{' '}
                            {locations.length > 0
                              ? `(${locations.length})`
                              : ''}
                          </span>
                        </div>
                      </div>
                      {locations && locations.length > 0 ? (
                        <div className='h-300px'>
                          <Table
                            selectable={SELECTION_TYPE.disabled}
                            loading={this.state.loading}
                            header={this.header}
                            items={locations}
                            link={'/administration/locations'}
                          />
                        </div>
                      ) : (
                        <div className='row row-no-entries'>
                          <div className='col'>
                            <span>
                              Diesem Kunden wurden noch keine Standorte
                              zugeordnet.
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </FormConsumer>
          </div>
        )}
      </AuthConsumer>
    );
  }
}

export default withRouter(CustomerEdit);
