import './Technician.scss';

import React, { Component, Fragment } from 'react';
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom';
import { isNumber, set } from 'lodash';

import { AuthConsumer } from '../../context/AuthContext';
import { ReactComponent as CalenderIcon } from '../../assets/icons/calender.svg';
import CheckInput from '../Form/CheckInput';
import { EMAIL_REGEXP } from '../regex';
import EditableInput from '../Form/EditableInput';
import { FormConsumer } from '../../context/FormContext';
import InPageNavigation from '../InPageNavigation/InPageNavigation';
import MapInput from '../Form/MapInput';
import NavLinkInput from '../Form/NavLinkInput';
import { Request } from '../../api/Request';
import { TasksDefinition } from '../Import/fields';
import { ReactComponent as TrashIcon } from '../../assets/icons/trash.svg';
import getTasks from '../Location/getTasks';
import { hasPermissionFunc } from '../../context/hasPermissionFunc';

interface TechnicianEditState {
  redirectList: boolean;
  error?: Error;
  isLoaded: boolean;
  item?: any;
  tasks?: TasksDefinition[];
  editing: boolean;
}

interface MatchParams {
  id: string;
}

interface TechnicianEditProps extends RouteComponentProps<MatchParams> {}

class TechnicianEdit extends Component<
  TechnicianEditProps,
  TechnicianEditState
> {
  state: TechnicianEditState = {
    redirectList: false,
    isLoaded: false,
    editing: false,
  };

  constructor(props: TechnicianEditProps) {
    super(props);
    this.save = this.save.bind(this);
    this.remove = this.remove.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  async loadData() {
    try {
      const id = this.props.match.params.id;
      const [item, tasks] = await Promise.all([
        Request.get('technicians', id),
        getTasks(),
      ]);
      this.setState({ isLoaded: true, item, tasks });
    } catch (error: any) {
      this.setState({
        isLoaded: true,
        error,
        redirectList: error.status === 404,
      });
    }
  }

  remove() {
    // eslint-disable-next-line no-restricted-globals
    if (confirm('Sind Sie sich sicher?')) {
      Request.delete('technicians', this.state.item._id).then(
        () => {
          this.setState({ redirectList: true });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
            redirectList: error.status === 404,
          });
        }
      );
    }
  }

  async save(k: string, value: any) {
    try {
      const data = set({}, k, value);
      const item = await Request.put('technicians', this.state.item._id, data);
      this.setState({ isLoaded: true, item });
    } catch (error: any) {
      this.setState({
        isLoaded: true,
        error,
        redirectList: error.status === 404,
      });
    }
  }

  getCustomer(item: any) {
    if (!item || !item.customer) return null;
    if (typeof item.customer === 'string') return item.customer;
    return item.customer._id;
  }

  renderBasic(item: any, isEditing: boolean, hasPermission: hasPermissionFunc) {
    return (
      <Fragment>
        <div className='row table-divider'>
          <div className='col'>
            <span>Stammdaten</span>
          </div>
        </div>
        <div className='row table-row input-row'>
          <div className='col'>
            <EditableInput
              title='Name'
              validate={(value: string) =>
                value !== undefined && value !== null && value.length > 4
              }
              readOnly={hasPermission(['technician:write']) !== true}
              value={item.name}
              callback={(value: string) => this.save('name', value)}
            />
          </div>
        </div>
        <div className='row table-row input-row'>
          <div className='col'>
            <EditableInput
              title='Kürzel'
              readOnly={hasPermission(['technician:write']) !== true}
              value={item.initialsOverwrite}
              defaultValue={item.defautlInitials}
              validate={(value: string) =>
                value === undefined ||
                (value !== undefined && value.length === 4)
              }
              callback={(value: string) =>
                this.save('initialsOverwrite', value)
              }
            />
          </div>
        </div>
        <div className='row table-row input-row'>
          <div className='col'>
            <EditableInput
              title='E-Mail'
              readOnly={hasPermission(['technician:write']) !== true}
              value={item.email}
              type='email'
              validate={(value: string) =>
                value && EMAIL_REGEXP.exec(value) !== null
              }
              callback={(value: string) => this.save('email', value)}
            />
          </div>
        </div>
        <div className='row table-row input-row'>
          <div className='col'>
            <EditableInput
              title='Telefon'
              readOnly={hasPermission(['technician:write']) !== true}
              value={item.phone}
              validate={(value: string) =>
                value !== undefined && value !== null && value.length > 2
              }
              callback={(value: string) => this.save('phone', value)}
            />
          </div>
        </div>
      </Fragment>
    );
  }

  renderCosts(item: any, isEditing: boolean, hasPermission: hasPermissionFunc) {
    return (
      <Fragment>
        <div className='row table-divider'>
          <div className='col'>
            <span>Kosten</span>
          </div>
        </div>
        <div className='row table-row input-row'>
          <div className='col'>
            <EditableInput
              title='pro Stunde'
              type='currency'
              validate={(value: number) => {
                return value && isNumber(value);
              }}
              readOnly={hasPermission(['technician:write']) !== true}
              value={item.costsPerHour}
              callback={(value: string) => this.save('costsPerHour', value)}
            />
          </div>
        </div>
      </Fragment>
    );
  }

  renderAnalysis(
    item: any,
    isEditing: boolean,
    hasPermission: hasPermissionFunc
  ) {
    return (
      <Fragment>
        <div className='row table-divider'>
          <div className='col'>
            <span>Dashboard & Auswertung</span>
          </div>
        </div>
        <div className='row table-row input-row'>
          <div className='col'>
            <EditableInput
              title='Zusammenfassen'
              readOnly={hasPermission(['technician:write']) !== true}
              value={item.analysis_group}
              callback={(value: string) => this.save('analysis_group', value)}
            />
          </div>
        </div>
      </Fragment>
    );
  }

  renderTestingEquipment(
    item: any,
    isEditing: boolean,
    hasPermission: hasPermissionFunc
  ) {
    return (
      <Fragment>
        <div className='row table-divider'>
          <div className='col'>
            <span>Prüfgerät</span>
          </div>
        </div>
        <div className='row table-row input-row'>
          <div className='col'>
            <EditableInput
              title='Gerät'
              validate={(value: string) =>
                value !== undefined && value !== null && value.length > 0
              }
              readOnly={hasPermission(['technician:write']) !== true}
              value={item.testingEquipment}
              callback={(value: string) => this.save('testingEquipment', value)}
            />
          </div>
        </div>
        <div className='row table-row input-row'>
          <div className='col'>
            <EditableInput
              title='S/N'
              validate={(value: string) =>
                value !== undefined && value !== null && value.length > 0
              }
              readOnly={hasPermission(['technician:write']) !== true}
              value={item.testingEquipmentID}
              callback={(value: string) =>
                this.save('testingEquipmentID', value)
              }
            />
          </div>
        </div>
      </Fragment>
    );
  }

  renderSkills(
    item: any,
    isEditing: boolean,
    hasPermission: hasPermissionFunc
  ) {
    return (
      <Fragment>
        <div className='row table-divider'>
          <div className='col'>
            <span>Fähigkeiten</span>
          </div>
        </div>
        <div className='row justify-content-between table-row input-row'>
          {this.state.tasks?.map((t) =>
            this.renderSkill(item, t, hasPermission)
          )}
        </div>
      </Fragment>
    );
  }

  renderSkill(
    item: any,
    skill: TasksDefinition,
    hasPermission: hasPermissionFunc
  ) {
    return (
      <div className='col-4'>
        <CheckInput
          title={skill.title}
          readOnly={hasPermission(['technician:write']) !== true}
          short={skill.key.toUpperCase()}
          value={item.skills ? item.skills[skill.key] : false}
          callback={(value: boolean) => this.save(`skills.${skill.key}`, value)}
        />
      </div>
    );
  }

  renderAddress(
    item: any,
    isEditing: boolean,
    hasPermission: hasPermissionFunc
  ) {
    return (
      <Fragment>
        <div className='row table-divider'>
          <div className='col'>
            <span>Wohnort</span>
          </div>
        </div>
        <div className='row input-row'>
          <div className='col'>
            <MapInput
              readOnly={hasPermission(['technician:write']) !== true}
              value={item.address}
              callback={(value: any) => this.save('address', value)}
            />
          </div>
        </div>
      </Fragment>
    );
  }

  renderOfftime(item: any) {
    return (
      <Fragment>
        <div className='row table-divider'>
          <div className='col'>
            <span>Urlaubsplan</span>
          </div>
        </div>
        <div className='row input-row'>
          <div className='col'>
            <NavLinkInput
              title='Kalender aufrufen'
              link={`/administration/technicians/${item._id}/offtime`}
            >
              <CalenderIcon />
            </NavLinkInput>
          </div>
        </div>
      </Fragment>
    );
  }

  renderLock(item: any) {
    return (
      <Fragment>
        <div className='row table-divider'>
          <div className='col'>
            <span>Sperre</span>
          </div>
        </div>
        <div className='row table-row input-row'>
          <div className='col-5 pr-0'>
            <CheckInput
              title='für Planung sperren'
              value={item.locked_planing}
              callback={(value: boolean) => this.save('locked_planing', value)}
            />
          </div>
        </div>
      </Fragment>
    );
  }

  render() {
    const { item } = this.state;

    if (this.state.redirectList) {
      return <Redirect to='/administration/technicians' />;
    }

    return (
      <AuthConsumer>
        {({ hasPermission }: { hasPermission: hasPermissionFunc }) => (
          <div className='technician container-inner'>
            <InPageNavigation to='/administration/technicians' item={item} />
            <div className='page-header page-header-line row justify-content-between'>
              <div className='col-6 col'>
                <h1 className='page-title'>
                  {item && item.name && item.name.length > 0 ? item.name : ''}
                </h1>
              </div>
              {hasPermission(['technician:write']) === true ? (
                <div className='col-6 col'>
                  <p className='delete float-right' onClick={this.remove}>
                    <TrashIcon />
                    Techniker löschen
                  </p>
                </div>
              ) : (
                ''
              )}
            </div>
            <FormConsumer>
              {({ isEditing }: { isEditing: boolean }) => (
                <div className={`page-content ${isEditing ? 'editing' : ''}`}>
                  <div className='row'>
                    <div className='col col-12 col-xs-6 col-md-6 col-lg-6 col-xl-6'>
                      {item && this.renderBasic(item, isEditing, hasPermission)}
                      {item &&
                        this.renderAddress(item, isEditing, hasPermission)}
                    </div>
                    <div className='col col-12 col-xs-6 col-md-6 col-lg-6 col-xl-6'>
                      {/* {item && this.renderOfftime(item)} */}
                      {item &&
                        this.renderSkills(item, isEditing, hasPermission)}
                      {item &&
                        this.renderTestingEquipment(
                          item,
                          isEditing,
                          hasPermission
                        )}
                      {item && this.renderCosts(item, isEditing, hasPermission)}
                      {item &&
                        this.renderAnalysis(item, isEditing, hasPermission)}
                      {item && this.renderLock(item)}
                    </div>
                  </div>
                </div>
              )}
            </FormConsumer>
          </div>
        )}
      </AuthConsumer>
    );
  }
}

export default withRouter(TechnicianEdit);
