import styled from 'styled-components';

const ExtraWrapper = styled.div<{
  editing?: boolean;
}>`
  margin-left: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  position: relative;
  &:after {
    content: '';
    display: block;
    clear: both;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 1px;
    background-color: #ccc;
  }
  :last-child {
    // display: ${(props) => (!props.editing ? 'none' : 'block')};
    display: block;
    // &:after {
    //   height: 50%;
    // }
  }
  > div {
    &:after {
      content: '';
      display: block;
      clear: both;
      position: absolute;
      left: -1rem;
      width: 1rem;
      height: 1px;
      top: 50%;
      background-color: #ccc;
    }
    position: relative;
    left: 1rem;
    background-color: #dbdbdb;
    padding: 0.5rem 1rem;
    font-size: 10px;
    border-radius: 4px;
    color: #009842;
    max-width: 140px;
    display: flex;
    align-items: center;
    justify-content: start;
    p {
      display: inline-block;
      margin: 0;
      line-height: 1rem;
      font-size: 1rem;
    }
    svg {
      margin-right: 0.5rem;
      width: 1rem;
      height: 1rem;
      display: inline-block;
      fill: #009842;
      stroke: #009842;
      g,
      path {
        fill: #009842;
        stroke: #009842;
      }
    }
  }
`;

export default ExtraWrapper;
